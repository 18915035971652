import {
  Avatar,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  User as UserComponent,
} from "@nextui-org/react";
import { useGetIdentity, useLogout } from "@refinedev/core";
import React from "react";
import { User } from "../../sdk";

export const AccountDropdown: React.FC = () => {
  const { data } = useGetIdentity<User>();
  const { mutate: logout } = useLogout();
  return (
    <Dropdown>
      <DropdownTrigger>
        <UserComponent
          avatarProps={{
            color: "secondary",
          }}
          name={`${data?.firstName} ${data?.lastName}`}
          description={data?.associationUsers?.[0]?.associationRole?.name}
        />
      </DropdownTrigger>
      <DropdownMenu>
        <DropdownSection>
          <DropdownItem key="UserName" isDisabled={true}>
            {data?.firstName} {data?.lastName}
          </DropdownItem>
        </DropdownSection>
        <DropdownSection>
          {/*<DropdownItem key="Settings" color={"secondary"}>*/}
          {/*  Settings*/}
          {/*</DropdownItem>*/}
          <DropdownItem
            key="Logout"
            color="danger"
            onPress={() => {
              logout();
            }}
          >
            Logout
          </DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );
};
