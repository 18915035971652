/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssociationInviteUsersDto } from '../models/AssociationInviteUsersDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AssociationInviteService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns any
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody: AssociationInviteUsersDto,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/association/{associationId}/invite',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public sendAdminInvites({
    requestBody,
  }: {
    requestBody: AssociationInviteUsersDto,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/association/{associationId}/invite/admin',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public sendMembershipCardLink({
    requestBody,
  }: {
    requestBody: AssociationInviteUsersDto,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/association/{associationId}/invite/send-membership-card-link',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
