import { AuthBindings } from "@refinedev/core";
import { User } from "../sdk";
import { ApiSdk } from "./api-sdk";
import { REFRESH_TOKEN_KEY, TOKEN_EXPIRES_KEY, TOKEN_KEY } from "./constants";

export const authProvider: AuthBindings = {
  login: async ({ username, email, password }) => {
    const failedLoginObject = {
      success: false,
      error: {
        name: "LoginError",
        message: "Invalid username or password",
      },
    };
    if ((username || email) && password) {
      // localStorage.setItem(TOKEN_KEY, username);
      try {
        const body: {
          token: string;
          refreshToken: string;
          tokenExpires: number;
          user: User;
        } = (await ApiSdk.auth.adminLogin({
          requestBody: {
            email: email!,
            password: password!,
          },
        })) as {
          token: string;
          refreshToken: string;
          tokenExpires: number;
          user: User;
        };

        if (!body?.token) {
          return {
            success: false,
            error: {
              name: "ApiError",
              message: "Invalid response from API",
            },
          };
        }

        localStorage.setItem(TOKEN_KEY, body.token);
        const token = jwtDecode(body.token);
        localStorage.setItem(TOKEN_EXPIRES_KEY, token.exp.toString());
        localStorage.setItem(REFRESH_TOKEN_KEY, body.refreshToken);
        return {
          success: true,
          redirectTo: "/",
        };
      } catch (error) {
        console.error("Error in authorize: ", error);
        return failedLoginObject;
      }
    }

    return failedLoginObject;
  },
  logout: async () => {
    // localStorage.removeItem(TOKEN_KEY);
    localStorage.clear();
    // await ApiSdk.auth.logout();
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    const expiration = localStorage.getItem(TOKEN_EXPIRES_KEY);
    console.log({
      token,
      expiration,
      expirationDate: new Date(Number(expiration) * 1000),
      currentDate: new Date(),
      isExpired: new Date(Number(expiration) * 1000) < new Date(),
    });
    if (
      token &&
      expiration &&
      new Date(Number(expiration) * 1000) > new Date()
    ) {
      return {
        authenticated: true,
      };
    }

    return {
      success: false,
      logout: true,
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      return null;
    }
    const body = await ApiSdk.auth.me();

    if (!body) {
      return {
        success: false,
        authenticated: false,
        redirectTo: "/login",
        logout: true,
      };
    }
    return body;
  },
  onError: async (error) => {
    console.error(error);
    return { error, logout: false };
  },

  forgotPassword: async ({ email }) => {
    try {
      await ApiSdk.auth.forgotPassword({
        requestBody: {
          email: email!,
        },
      });
      return {
        success: true,
        redirectTo: "/login",
      };
    } catch (error) {
      console.error("Error in forgotPassword: ", error);
      return {
        success: false,
        error: {
          name: "ForgotPasswordError",
          message: "An error occurred while processing your request",
        },
      };
    }
  },
  updatePassword: async ({ password, repeatPassword, hash }) => {
    try {
      await ApiSdk.auth.resetPassword({
        requestBody: {
          password: password!,
          repeatPassword: repeatPassword!,
          hash: hash!,
        },
      } as any);
      return {
        success: true,
        redirectTo: "/login",
      };
    } catch (error) {
      console.error("Error in updatePassword: ", error);

      return {
        success: false,
        error: {
          name: "updatePasswordError",
          message: "An error occurred while processing your request",
        },
      };
    }
  },
};

export const jwtDecode = (token: string) => {
  if (!token) {
    return null;
  }

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  const decodedToken = JSON.parse(window.atob(base64));

  return decodedToken;
};
