/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AssociationService } from './services/AssociationService';
import { AssociationGolfClassService } from './services/AssociationGolfClassService';
import { AssociationInviteService } from './services/AssociationInviteService';
import { AssociationMembershipService } from './services/AssociationMembershipService';
import { AssociationRoleService } from './services/AssociationRoleService';
import { AssociationUserService } from './services/AssociationUserService';
import { AuthService } from './services/AuthService';
import { CertificateService } from './services/CertificateService';
import { ChangeRequestService } from './services/ChangeRequestService';
import { CoachClassService } from './services/CoachClassService';
import { DefaultService } from './services/DefaultService';
import { FilesService } from './services/FilesService';
import { GolfProfessionalLevelService } from './services/GolfProfessionalLevelService';
import { UsersService } from './services/UsersService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class PgaSdk {
  public readonly association: AssociationService;
  public readonly associationGolfClass: AssociationGolfClassService;
  public readonly associationInvite: AssociationInviteService;
  public readonly associationMembership: AssociationMembershipService;
  public readonly associationRole: AssociationRoleService;
  public readonly associationUser: AssociationUserService;
  public readonly auth: AuthService;
  public readonly certificate: CertificateService;
  public readonly changeRequest: ChangeRequestService;
  public readonly coachClass: CoachClassService;
  public readonly default: DefaultService;
  public readonly files: FilesService;
  public readonly golfProfessionalLevel: GolfProfessionalLevelService;
  public readonly users: UsersService;
  public readonly request: BaseHttpRequest;
  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });
    this.association = new AssociationService(this.request);
    this.associationGolfClass = new AssociationGolfClassService(this.request);
    this.associationInvite = new AssociationInviteService(this.request);
    this.associationMembership = new AssociationMembershipService(this.request);
    this.associationRole = new AssociationRoleService(this.request);
    this.associationUser = new AssociationUserService(this.request);
    this.auth = new AuthService(this.request);
    this.certificate = new CertificateService(this.request);
    this.changeRequest = new ChangeRequestService(this.request);
    this.coachClass = new CoachClassService(this.request);
    this.default = new DefaultService(this.request);
    this.files = new FilesService(this.request);
    this.golfProfessionalLevel = new GolfProfessionalLevelService(this.request);
    this.users = new UsersService(this.request);
  }
}

