import React, { ReactNode } from "react";
import { Input, Modal, ModalContent, ModalHeader } from "@nextui-org/react";

interface Props extends React.HTMLProps<HTMLDivElement> {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  header: ReactNode;
}

const Drawer: React.FC<Props> = ({ ...props }) => {
  return (
    <Modal
      scrollBehavior="inside"
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      placement="center"
      backdrop="opaque"
      size="full"
      classNames={{
        wrapper: "flex justify-end bg-light md:bg-transparent",
        base: "shadow-none",
      }}
      motionProps={{
        variants: {
          enter: {
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeOut",
            },
          },
          exit: {
            x: 50,
            opacity: 0,
            transition: {
              duration: 0.2,
              ease: "easeIn",
            },
          },
        },
      }}
      className="rounded-md max-w-xl w-full h-screen max-h-screen"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 p-4 text-3xl">
              {props.header}
            </ModalHeader>
            <div className="flex flex-col gap-4 p-4 px-6">{props.children}</div>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Drawer;
