/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGolfProfessionalLevelDto } from '../models/CreateGolfProfessionalLevelDto';
import type { GolfProfessionalLevel } from '../models/GolfProfessionalLevel';
import type { UpdateGolfProfessionalLevel } from '../models/UpdateGolfProfessionalLevel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GolfProfessionalLevelService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns GolfProfessionalLevel
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody: CreateGolfProfessionalLevelDto,
  }): CancelablePromise<GolfProfessionalLevel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/golf-professional-level',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any[]
   * @throws ApiError
   */
  public find(): CancelablePromise<any[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/golf-professional-level',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public updateById({
    id,
    requestBody,
    relations,
  }: {
    id: number,
    requestBody: UpdateGolfProfessionalLevel,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/golf-professional-level/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public deleteById({
    id,
    relations,
  }: {
    id: number,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/golf-professional-level/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
    });
  }
}
