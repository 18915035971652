import { Authenticated, ErrorComponent, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import routerBindings, {
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import React from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Layout } from "./components/layout";
import { GlobalProviders } from "./global-providers";
import { authProvider } from "./lib/authProvider";
import { notificationProvider } from "./lib/notification-provider";
import { AssociationGolfClasses } from "./pages/association/association-golf-class/association-golf-class";
import { AssociationRoles } from "./pages/association/association-role/association-roles";
import { AssociationEdit } from "./pages/association/association-upsert";
import { AssociationList } from "./pages/association/list";
import { AssociationDashboard } from "./pages/association/dashboard/dashboard";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { CertificateList } from "./pages/certificate/certificate-list";
import { ChangeRequestList } from "./pages/change-request/change-request-list";
import { ChangeRequestView } from "./pages/change-request/change-request-view";
import { CoachClassList } from "./pages/coach-class/coach-class-list";
import { GolfProfessionalLevels } from "./pages/golf-class/golf-professional-levels";

import { MembersList } from "./pages/members";
import { ForgotPassword } from "./pages/forgot-password";
import { Login } from "./pages/login";
import { MemberView } from "./pages/members/user-view/user-view";
import { PublicUserView } from "./pages/public-user-view/public-user-view";
import { ResetPassword } from "./pages/reset-password";
import { apiResources, ResourceIdentifier } from "./resources";
import { dataProvider } from "./rest-data-provider";
import { AssociationRedirect } from "./pages/association/association-redirect";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      {/*<GitHubBanner />*/}

      <GlobalProviders>
        <RefineKbarProvider>
          <Refine
            authProvider={authProvider}
            dataProvider={dataProvider(
              import.meta.env.VITE_PUBLIC_BACKEND_API_URL
            )}
            i18nProvider={i18nProvider}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            resources={apiResources}
            options={{
              // syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              projectId: "rhafgh-aLP9JS-0GTfcM",
            }}
          >
            <ToastContainer />
            <Routes>
              <Route
                path="/public/:userId/:associationUserId"
                element={<PublicUserView />}
              />

              <Route
                element={
                  <Authenticated
                    key={"authenticationComponent"}
                    v3LegacyAuthProviderCompatible={false}
                  >
                    <Layout>
                      {
                        <>
                          <Outlet />
                        </>
                      }
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index path={"/"} element={<AssociationRedirect />} />
                <Route
                  index
                  element={
                    <NavigateToResource
                      resource={ResourceIdentifier.Association}
                    />
                  }
                />
                <Route
                  path={"/association"}
                  element={<AssociationList />}
                ></Route>
                <Route path={"/association/:associationId"}>
                  <Route index element={<AssociationDashboard />} />
                  <Route path="edit" element={<AssociationEdit />} />

                  <Route path="role">
                    <Route index element={<AssociationRoles />} />
                  </Route>

                  <Route path="golf-class">
                    <Route index element={<AssociationGolfClasses />} />
                  </Route>
                </Route>
                <Route path="/association/:associationId/user">
                  <Route index element={<MembersList />} />
                  {/*<Route path="create" element={<MemberCreate />} />*/}
                  {/*<Route path=":id/edit" element={<MemberEdit />} />*/}
                  <Route path=":id" element={<MemberView />} />
                </Route>
                <Route path="/association/:associationId/change-request">
                  <Route index element={<ChangeRequestList />} />
                  {/*<Route path="create" element={<MemberCreate />} />*/}
                  {/*<Route path=":id/edit" element={<MemberEdit />} />*/}
                  <Route path=":id" element={<ChangeRequestView />} />
                </Route>
                <Route path="/certificate">
                  <Route index element={<CertificateList />} />
                </Route>
                <Route path="/golf-professional-level">
                  <Route index element={<GolfProfessionalLevels />} />
                </Route>
                <Route path="/coach-class">
                  <Route index element={<CoachClassList />} />
                </Route>
              </Route>
              <Route path="*" element={<ErrorComponent />} />
              <Route
                element={
                  <Authenticated
                    key="authenticated-outer"
                    fallback={<Outlet />}
                  >
                    <NavigateToResource
                      resource={ResourceIdentifier.Association}
                    />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                {/*<Route path="/register" element={<Register />} />*/}
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/" element={<ResetPassword />} />
              </Route>
            </Routes>
            <RefineKbar />
            <UnsavedChangesNotifier />
            <DocumentTitleHandler handler={() => "PGA Admin"} />
          </Refine>
        </RefineKbarProvider>
      </GlobalProviders>
    </BrowserRouter>
  );
}

export default App;
