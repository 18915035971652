import React from "react";

export const OfficialCgpCardHeader: React.FC = () => {
  return (
    <div className={"absolute text-center w-full top-3"}>
      <span
        className={
          "text-secondary uppercase tracking-[0.2rem] font-extralight text-[9px]"
        }
      >
        Official CPG card
      </span>
    </div>
  );
};
