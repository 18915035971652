import { CrudOperators } from "@refinedev/core";

export const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    // case "ne":
    // case "gte":
    // case "lte":
    //   return `$${operator}`;
    // case "contains":
    //   return "$like";
    // case "eq":
    default:
      return `\$${operator}`;
  }
};
