/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthConfirmEmailDto } from '../models/AuthConfirmEmailDto';
import type { AuthEmailLoginDto } from '../models/AuthEmailLoginDto';
import type { AuthForgotPasswordDto } from '../models/AuthForgotPasswordDto';
import type { AuthRegisterLoginDto } from '../models/AuthRegisterLoginDto';
import type { AuthResetPasswordDto } from '../models/AuthResetPasswordDto';
import type { AuthUpdateDto } from '../models/AuthUpdateDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns any
   * @throws ApiError
   */
  public login({
    requestBody,
  }: {
    requestBody: AuthEmailLoginDto,
  }): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/auth/email/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public adminLogin({
    requestBody,
  }: {
    requestBody: AuthEmailLoginDto,
  }): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/auth/admin/email/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public register({
    requestBody,
  }: {
    requestBody: AuthRegisterLoginDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/auth/email/register',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public confirmEmail({
    requestBody,
  }: {
    requestBody: AuthConfirmEmailDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/auth/email/confirm',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public forgotPassword({
    requestBody,
  }: {
    requestBody: AuthForgotPasswordDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/auth/forgot/password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public resetPassword({
    requestBody,
  }: {
    requestBody: AuthResetPasswordDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/auth/reset/password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public me(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/auth/me',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public update({
    requestBody,
  }: {
    requestBody: AuthUpdateDto,
  }): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/auth/me',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public delete(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/auth/me',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public refresh(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/auth/refresh',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public logout(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/auth/logout',
    });
  }
}
