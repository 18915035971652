import { cn } from "@nextui-org/react";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Card1 } from "../members/user-view/card-1";
import { Card2 } from "../members/user-view/card-2";
import { Card3 } from "../members/user-view/card-3";
import { CardQrCode } from "../members/user-view/qr-code-card";

export const UserCards = ({ isAdminView }: { isAdminView?: boolean }) => {
  return (
    <div
      className={cn("max-w-[450px] max-h-[800px]", !isAdminView && "mx-auto")}
    >
      <Carousel
        emulateTouch={true}
        showThumbs={false}
        showStatus={false}
        swipeScrollTolerance={30}
        infiniteLoop={true}
        selectedItem={1}
      >
        <CardQrCode isAdminView={isAdminView} />
        <Card1 isAdminView={isAdminView} />
        <Card2 isAdminView={isAdminView} />
        <Card3 isAdminView={isAdminView} />
      </Carousel>
    </div>
  );
};
