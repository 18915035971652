import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Tooltip } from "@nextui-org/react";
import { useCreate, useInvalidate, useUpdate } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { IconHelpCircle } from "@tabler/icons-react";
import React from "react";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { FormInput } from "../../../components/ui/form/form-input";
import { FormSwitch } from "../../../components/ui/form/form-switch";
import { useActiveAssociation } from "../../../context/active-association.context";
import { useDrawer } from "../../../context/drawer.context";
import { ResourceIdentifier } from "../../../resources";
import { AssociationRole } from "../../../sdk";
export const UpsertRoleForm: React.FC<{ role?: AssociationRole }> = ({
  role,
}) => {
  const { closeDrawer } = useDrawer();
  const association = useActiveAssociation();

  const schema = yup.object().shape({
    name: yup.string().required(),
    hasAdminAccess: yup.boolean().nullable(),
  });

  const invalidate = useInvalidate();

  const form = useForm({
    defaultValues: {
      name: "",
      hasAdminAccess: false,
    },
    values: {
      name: role?.name || "",
      hasAdminAccess: role?.hasAdminAccess || false,
    },
    resolver: yupResolver(schema),
    refineCoreProps: {
      resource: ResourceIdentifier.AssociationRole,
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const [loading, setLoading] = React.useState(false);

  const { mutate: updateAssociationRole } = useUpdate();
  const { mutate: createAssociationRole } = useCreate();

  const onSuccess = async () => {
    await invalidate({
      resource: ResourceIdentifier.Association,
      id: association?.id,
      invalidates: ["detail"],
    });
    closeDrawer();
  };

  const onSettled = () => {
    setLoading(false);
  };
  const upsertAssociation = async (data: yup.InferType<typeof schema>) => {
    setLoading(true);
    const resource = ResourceIdentifier.AssociationRole;
    const upsertArgs = {
      resource,
      meta: {
        associationId: association?.id,
      },
      values: {
        ...data,
        association: {
          id: association,
        }?.id,
      },
      successNotification: {
        message: "Association updated successfully",
        type: "success" as const,
      },
      errorNotification: (error: any) => {
        console.log("ERROR ", error);
        return {
          message: "Error updating association",
          description: error?.message,
          type: "error" as const,
          position: "top-center",
        };
      },
    };

    if (!role?.id) {
      createAssociationRole(
        {
          ...upsertArgs,
        },
        {
          onSuccess: onSuccess,
          onSettled: onSettled,
        }
      );
    } else {
      await updateAssociationRole(
        {
          ...upsertArgs,
          id: role?.id!,
        },
        {
          onSuccess: onSuccess,
          onSettled: onSettled,
        }
      );
    }
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(upsertAssociation)}>
        <div className={"grid grid-cols-2 gap-4 mb-6"}>
          <FormInput
            name={"name"}
            label={"Role name"}
            variant={"bordered"}
            control={control}
          />

          <FormSwitch
            name={"hasAdminAccess"}
            control={control}
            label={
              <Tooltip
                content={
                  "Whether user can access the admin dashboard and edit association and association related data"
                }
              >
                <div className={"flex flex-row justify-center align-baseline"}>
                  Has admin access{" "}
                  <IconHelpCircle className={"h-4 w-4 text-gray-500"} />
                </div>
              </Tooltip>
            }
          />
        </div>
        <Button
          fullWidth
          size={"lg"}
          color={"secondary"}
          type={"submit"}
          isLoading={loading}
          isDisabled={loading}
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
};
