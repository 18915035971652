import { Select, SelectProps } from "@nextui-org/react";
import React, { useEffect } from "react";
import { Control, Controller } from "react-hook-form";

export type FormInputProps = {
  name: string;
  control: Control<any, any>;
} & SelectProps;
export const FormSelect: React.FC<FormInputProps> = ({
  name,
  children,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={props.control}
      render={({ field, fieldState, formState }) => {
        const [values, setValues] = React.useState(new Set([]));

        const handleSelectionChange = (e: any) => {
          if (props.selectionMode !== "multiple") {
            field.onChange(e?.target?.value);
          } else {
            field.onChange(e.target.value.split(",").filter((v: any) => v));
          }
        };

        return (
          <>
            <Select
              isInvalid={!!formState.errors?.[name]?.message}
              errorMessage={formState.errors?.[name]?.message?.toString()}
              selectedKeys={
                field.value
                  ? Array.isArray(field.value)
                    ? field.value
                    : [field.value]
                  : []
              }
              onChange={handleSelectionChange}
              key={field.name}
              {...props}
            >
              {children}
            </Select>
          </>
        );
      }}
    ></Controller>
  );
};
