import {
  Badge,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from "@nextui-org/react";
import { useCustom, useDelete, useInvalidate, useTable } from "@refinedev/core";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import React, { FC, useCallback, useState } from "react";
import { Key } from "react-aria-components";
import { useDrawer } from "../../context/drawer.context";
import { useAssociationUserShow } from "../../context/user-show.context";
import { ResourceIdentifier } from "../../resources";
import { AssociationMembership, Certificate } from "../../sdk";
import { TableTopContent } from "../table/table-top-content";
import { ConfirmationModal } from "../ui/confirmation-modal";
import { UpsertMembershipForm } from "./membership-upsert-form";

export const MembershipList: FC = () => {
  const { associationUser } = useAssociationUserShow();
  const columns = [
    { header: "Id", key: "id", sortable: false },
    { header: "Start date", key: "startDate", sortable: false },
    { header: "End date", key: "endDate", sortable: false },
    { header: "Active", key: "isActive", sortable: false },
    { header: "Actions", key: "actions", sortable: false },
  ];

  const { toggleDrawer } = useDrawer();
  const { mutate: deleteProduct } = useDelete();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [deleteItemId, setDeleteItemId] = useState<null | number>(null);

  const { data } = useCustom<AssociationMembership[]>({
    url: `${import.meta.env.VITE_PUBLIC_BACKEND_API_URL}/association/${
      associationUser?.association?.id
    }/membership/${associationUser?.id}`,
    method: "get",
    queryOptions: {
      enabled: !!associationUser?.id,
    },
  });

  const invalidate = useInvalidate();

  const renderCell = useCallback(
    (columnKey: Key, item: any) => {
      if (columnKey === "actions") {
        return (
          <TableCell>
            <div className="flex gap-1 items-center justify-end">
              <Button
                isIconOnly
                size="sm"
                variant="light"
                color="default"
                aria-label="Edit"
                className="rounded-full"
                onPress={() => {
                  toggleDrawer({
                    opened: true,
                    content: <UpsertMembershipForm membership={item} />,
                    headerText: "Edit certificate",
                  });
                }}
              >
                <IconPencil className="h-4 w-4" />
              </Button>
              <Button
                isIconOnly
                variant="light"
                color="danger"
                size="md"
                aria-label="Delete"
                className="rounded-full"
                onPress={() => {
                  setDeleteItemId(item.id);
                  onOpen();
                }}
              >
                <IconTrash className="h-4 w-4" />
              </Button>
            </div>
          </TableCell>
        );
      }

      if (columnKey?.toString().includes("Date")) {
        return (
          <TableCell>
            {new Date(item[columnKey]).toLocaleDateString()}
          </TableCell>
        );
      }

      if (columnKey === "isActive") {
        const currentDate = Date.now();
        return (
          <TableCell>
            {new Date(item["startDate"]).getTime() < currentDate &&
              new Date(item["endDate"]).getTime() > currentDate && (
                <Chip color="success">Active</Chip>
              )}
          </TableCell>
        );
      }

      return <TableCell>{item[columnKey]}</TableCell>;
    },
    [data?.data]
  );

  return (
    <div className={"flex flex-grow max-w-lg"}>
      <Table
        isStriped
        aria-label="Certificate  table"
        topContent={
          <>
            <span className={"text-xl font-bold"}>Membership</span>
            <TableTopContent
              search={false}
              buttons={
                <>
                  <Button
                    key={"new-membership"}
                    variant={"bordered"}
                    onPress={() => {
                      toggleDrawer({
                        opened: true,
                        content: <UpsertMembershipForm />,
                        headerText: "Add membership",
                      });
                    }}
                  >
                    New membership
                  </Button>
                </>
              }
            />
          </>
        }
      >
        <TableHeader columns={columns}>
          {(column) => {
            if (column.key === "actions") {
              return (
                <TableColumn
                  allowsSorting={column.sortable}
                  key={column.key}
                  className="text-end pr-8"
                >
                  {column.header}
                </TableColumn>
              );
            }

            return (
              <TableColumn allowsSorting={column.sortable} key={column.key}>
                {column.header}
              </TableColumn>
            );
          }}
        </TableHeader>
        {data?.data.length ? (
          <TableBody items={data?.data.sort((a, b) => a.id - b.id)}>
            {(item) => {
              return (
                <TableRow key={item.id}>
                  {(columnKey) => {
                    return renderCell(columnKey, item);
                  }}
                </TableRow>
              );
            }}
          </TableBody>
        ) : (
          <TableBody emptyContent={"No rows to display."}>{[]}</TableBody>
        )}
      </Table>
      {isOpen ? (
        <ConfirmationModal
          isOpen={isOpen}
          onConfirm={() =>
            deleteProduct(
              {
                resource: ResourceIdentifier.Membership,
                meta: {
                  associationId: associationUser?.association?.id,
                },
                id: deleteItemId as number,
                successNotification: {
                  message: "Membership deleted successfully",
                  type: "success",
                },
                errorNotification: (error) => {
                  return {
                    message: "Error deleting membership",
                    description: error?.message,
                    type: "error",
                    position: "top-center",
                  };
                },
              },
              {
                onSuccess: async () => {
                  await invalidate({
                    resource: ResourceIdentifier.UserMembership,
                    invalidates: ["all"],
                  });
                },
              }
            )
          }
          onOpenChange={onOpenChange}
          warningMessage={`You are about to delete product with id ${deleteItemId} from the database. This action is irreversible.`}
        />
      ) : null}
    </div>
  );
};
