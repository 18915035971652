import React, { PropsWithChildren } from "react";
import { ActiveAssociationProvider } from "../../context/active-association.context";
import { DrawerProvider } from "../../context/drawer.context";
import { Breadcrumb } from "../breadcrumb";
import { Menu } from "../menu";
import { Sidebar } from "../sidebar/sidebar";
import GlobalDrawer from "../ui/global-drawer";

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <main className={""}>
      <ActiveAssociationProvider>
        <DrawerProvider>
          <GlobalDrawer />
          <div className="layout flex flex-col">
            <Sidebar />
            <div className="content md:ml-[300px] md:px-10 px-4">
              <Menu />
              {/*<Breadcrumb />*/}
              <div>{children}</div>
            </div>
          </div>
        </DrawerProvider>
      </ActiveAssociationProvider>
    </main>
  );
};
