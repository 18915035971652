import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  cn,
  Divider,
  Image,
} from "@nextui-org/react";
import { useApiUrl, useNavigation } from "@refinedev/core";
import { IconSettings } from "@tabler/icons-react";
import React, { ReactNode } from "react";
import { useActiveAssociation } from "../../../context/active-association.context";
import { useDrawer } from "../../../context/drawer.context";
import { getCountryAlpha2Code, getCountryFlagUrl } from "../../../lib/flag-map";
import { AssociationEdit } from "../association-upsert";

const addPadding = ["albania", "jamaica", "iceland", "slovakia", "malta"];
export const AssociationInfo: React.FC = () => {
  const association = useActiveAssociation();
  const apiUrl = useApiUrl();

  return (
    <>
      <Card
        className={
          "max-w-1/2 w-full p-2 bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))]  from-primary-shade via-primary to-primary-shade"
        }
      >
        <CardHeader className={"flex md:flex-row flex-col"}>
          <div
            className={
              "text-3xl text-bold w-full flex flex-row justify-between text-light uppercase "
            }
          >
            <div
              className={
                "flex justify-center align-middle items-center gap-x-4 gap-y-4 text-center"
              }
            >
              {association?.logoId && (
                <Image
                  className={cn(
                    " backdrop-blur-2xl",
                    addPadding.includes(association?.country?.toLowerCase())
                      ? "p-2 bg-light rounded-xl"
                      : "p-2 rounded-full"
                  )}
                  src={`${apiUrl}/files/${association?.logoId}`}
                  width={100}
                  height={100}
                />
              )}
              {association?.name}
            </div>
            <DashboardActions />
          </div>
        </CardHeader>
        <CardBody>
          <div className={"grid grid-cols-2 gap gap-y-2 gap-x-8"}>
            <EditableProperty label={"name"} value={association?.name} />
            <EditableProperty
              label={"phone number"}
              value={association?.phoneNumber}
            />
            <EditableProperty label={"courses"} value={association?.courses} />
            <EditableProperty label={"email"} value={association?.email} />
            <EditableProperty
              label={"year formed"}
              value={association?.yearFormed}
            />
            <EditableProperty label={"website"} value={association?.website} />
            <EditableProperty label={"country"} value={association?.country}>
              <Image
                src={getCountryFlagUrl(association?.country)}
                width={50}
                height={50}
              />
            </EditableProperty>
          </div>
        </CardBody>
        <CardFooter>
          <div className={"flex flex-row justify-center items-center w-full"}>
            <Image
              className={"rounded-full"}
              src={getCountryFlagUrl(association?.country)}
              width={75}
              height={75}
            />
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

const EditableProperty = ({
  label,
  value,
  children,
}: {
  label: string;
  value?: string | number;
  children?: ReactNode;
}) => {
  return (
    <>
      <div className={"flex flex-col gap-x-2 p-1"}>
        <span className={"text-light/50 capitalize text-sm"}>{label}</span>
        <span className={"text-light "}>{value}</span>
      </div>
    </>
  );
};

const DashboardActions = () => {
  const { toggleDrawer } = useDrawer();
  return (
    <div className={"absolute right-1 top-5"}>
      <Button
        variant={"light"}
        color={"secondary"}
        onPress={() => {
          toggleDrawer({
            opened: true,
            content: <AssociationEdit />,
            headerText: "Edit association",
          });
        }}
      >
        <IconSettings />
      </Button>
    </div>
  );
};
