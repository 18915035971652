import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
} from "@nextui-org/react";
import {
  useApiUrl,
  useGo,
  useNavigation,
  useOne,
  useUpdate,
} from "@refinedev/core";
import {
  IconPhotoMinus,
  IconSquareCheckFilled,
  IconSquareXFilled,
} from "@tabler/icons-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useActiveAssociation } from "../../context/active-association.context";
import { ResourceIdentifier } from "../../resources";
import { ChangeRequest } from "../../sdk";
import { StatusBadge } from "./change-request-status-badge";

export const ChangeRequestView = () => {
  const association = useActiveAssociation();
  const { id: changeRequestId } = useParams();

  const { data } = useOne<ChangeRequest>({
    resource: ResourceIdentifier.ChangeRequest,
    id: changeRequestId,
  });

  return (
    <>
      <div>
        {data?.data?.type === "user-image" && (
          <ImageChangeRequest changeRequest={data.data} />
        )}
      </div>
    </>
  );
};

const ImageChangeRequest = ({
  changeRequest,
}: {
  changeRequest: ChangeRequest;
}) => {
  const apiUrl = useApiUrl();
  return (
    <>
      <div className={"flex justify-start flex-col gap-y-4 max-w-3xl"}>
        <div className={"flex justify-center flex-col"}>
          <div className={"flex flex-row mb-8"}>
            <h1 className="font-bold text-xl">Profile image change</h1>
          </div>
          <div className={"flex flex-col sm:flex-row flex-wrap gap-2 mb-4"}>
            <Card>
              <CardHeader>
                <p className="text-tiny uppercase font-bold">Before</p>
              </CardHeader>
              <CardBody>
                <Image
                  className={"p-2 backdrop-blur-2xl "}
                  src={`${apiUrl}/files/${changeRequest.from}`}
                  width={300}
                  height={300}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <p className="text-tiny uppercase font-bold">After</p>
              </CardHeader>
              <CardBody>
                {changeRequest?.to && (
                  <Image
                    className={"p-2 backdrop-blur-2xl "}
                    src={`${apiUrl}/files/${changeRequest.to}`}
                    width={300}
                    height={300}
                  />
                )}
                {!changeRequest?.to && (
                  <div
                    className={
                      "flex flex-col justify-center items-center w-[300px] h-[250px] text-gray-500"
                    }
                  >
                    <IconPhotoMinus
                      size={100}
                      stroke={0.5}
                      // className={"w-64 h-64"}
                    />
                    No image
                  </div>
                )}
              </CardBody>
              <CardFooter>
                <div className={"flex flex-row justify-center w-full"}>
                  <StatusBadge changeRequest={changeRequest} />
                </div>
              </CardFooter>
            </Card>
          </div>
          <ApproveButtons changeRequest={changeRequest} />
        </div>
      </div>
    </>
  );
};

const ApproveButtons = ({
  changeRequest,
}: {
  changeRequest: ChangeRequest;
}) => {
  const { mutateAsync: updateResource, isLoading } = useUpdate();
  const { goBack } = useNavigation();
  return (
    <>
      <div className={"flex flex-row gap-4 justify-center"}>
        <Button
          isDisabled={changeRequest.status !== "pending"}
          isLoading={isLoading}
          size={"lg"}
          color={"danger"}
          onPress={async () => {
            await updateResource({
              resource: ResourceIdentifier.ChangeRequest,
              id: changeRequest.id,
              values: {
                status: "rejected",
              },
              successNotification: {
                message: "Profile image change rejected",
                type: "success",
              },
            });
            goBack();
          }}
        >
          Reject
          <IconSquareXFilled />
        </Button>
        <Button
          isDisabled={changeRequest.status !== "pending"}
          isLoading={isLoading}
          size={"lg"}
          color={"success"}
          onPress={async () => {
            await updateResource({
              resource: ResourceIdentifier.ChangeRequest,
              id: changeRequest.id,
              values: {
                status: "approved",
              },
              successNotification: {
                message: "Profile image change approved",
                type: "success",
              },
            });
            goBack();
          }}
        >
          Approve
          <IconSquareCheckFilled />
        </Button>
      </div>
    </>
  );
};
