import { SelectItem } from "@nextui-org/react";
import { useList } from "@refinedev/core";
import React from "react";
import { useActiveAssociation } from "../../../context/active-association.context";
import { ResourceIdentifier } from "../../../resources";
import {
  AssociationUser,
  Certificate,
  CoachClass,
  GolfProfessionalLevel,
} from "../../../sdk";
import { FormSelect } from "../../ui/form/form-select";

type UserUpsertFormSelectProps = {
  associationUser?: AssociationUser;
  control: any;
};

export const RoleClassSelect: React.FC<UserUpsertFormSelectProps> = ({
  associationUser,
  control,
}) => {
  const activeAssociation = useActiveAssociation();

  return activeAssociation?.associationRoles ? (
    <FormSelect
      name={"role"}
      label={"Role"}
      variant={"bordered"}
      control={control}
      // selectedKeys={
      //   associationUser?.associationRole?.id
      //     ? [associationUser?.associationRole?.id]
      //     : []
      // }
    >
      {activeAssociation?.associationRoles.map((role) => {
        return (
          <SelectItem key={role.id} value={role.id}>
            {role.name}
          </SelectItem>
        );
      })}
    </FormSelect>
  ) : null;
};

export const AssociationGolfClassSelect: React.FC<
  UserUpsertFormSelectProps
> = ({ associationUser, control }) => {
  const activeAssociation = useActiveAssociation();

  return activeAssociation?.associationGolfClasses ? (
    <>
      <FormSelect
        name={"associationGolfClass"}
        label={"Association golf class"}
        // selectedKeys={
        //   associationUser?.golfClass?.id ? [associationUser?.golfClass?.id] : []
        // }
        variant={"bordered"}
        control={control}
      >
        {activeAssociation?.associationGolfClasses?.map((golfClass) => {
          return (
            <SelectItem
              key={golfClass.id.toString()}
              value={golfClass.id.toString()}
            >
              {golfClass.name}
            </SelectItem>
          );
        })}
      </FormSelect>
    </>
  ) : null;
};

export const CoachClassSelect: React.FC<UserUpsertFormSelectProps> = ({
  associationUser,
  control,
}) => {
  const { data: coachClasses } = useList<CoachClass>({
    resource: ResourceIdentifier.CoachClass,
  });

  return coachClasses?.data ? (
    <FormSelect
      name={"coachClass"}
      label={"Coach class"}
      variant={"bordered"}
      control={control}
    >
      {coachClasses.data.length > 0 ? (
        coachClasses?.data?.map((coachClass) => {
          return (
            <SelectItem
              key={coachClass.id.toString()}
              value={coachClass.id.toString()}
            >
              {coachClass.name}
            </SelectItem>
          );
        })
      ) : (
        <SelectItem
          key={"coach_class_undefined"}
          value={"false"}
          isDisabled={true}
        >
          No CPG coach classes defined
        </SelectItem>
      )}
    </FormSelect>
  ) : null;
};

export const GolfClassSelect: React.FC<UserUpsertFormSelectProps> = ({
  associationUser,
  control,
}) => {
  const { data: golfClasses } = useList<GolfProfessionalLevel>({
    resource: ResourceIdentifier.GolfProfessionalLevel,
  });

  return golfClasses?.data ? (
    <>
      <FormSelect
        name={"golfClass"}
        label={"Golf Professional Level"}
        // selectedKeys={
        //   associationUser?.golfClass?.id ? [associationUser?.golfClass?.id] : []
        // }

        variant={"bordered"}
        control={control}
      >
        {golfClasses.data.length > 0 ? (
          golfClasses?.data?.map((golfClasses) => {
            return (
              <SelectItem
                key={golfClasses.id.toString()}
                value={golfClasses.id.toString()}
              >
                {golfClasses.name}
              </SelectItem>
            );
          })
        ) : (
          <SelectItem
            key={"golf_class_undefined"}
            value={"false"}
            isDisabled={true}
          >
            No Golf Professional Levels defined
          </SelectItem>
        )}
      </FormSelect>
    </>
  ) : null;
};

export const CertificatesSelect: React.FC<UserUpsertFormSelectProps> = ({
  associationUser,
  control,
}) => {
  const { data: certificates } = useList<Certificate>({
    resource: ResourceIdentifier.Certificate,
  });

  return certificates?.data ? (
    <>
      <FormSelect
        name={"certificates"}
        label={"Certificates"}
        selectionMode={"multiple"}
        variant={"bordered"}
        control={control}
      >
        {certificates.data.length > 0 ? (
          certificates?.data?.map((certificates) => {
            return (
              <SelectItem
                key={certificates.id.toString()}
                value={certificates.id.toString()}
              >
                {certificates.name}
              </SelectItem>
            );
          })
        ) : (
          <SelectItem
            key={"certificate_undefined"}
            value={"false"}
            isDisabled={true}
          >
            No CPG Certificates defined
          </SelectItem>
        )}
      </FormSelect>
    </>
  ) : null;
};
