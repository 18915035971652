import { Button, Card, CardBody, Input } from "@nextui-org/react";
import { useNotification, useUpdatePassword } from "@refinedev/core";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { IconEye, IconEyeClosed } from "@tabler/icons-react";
import { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();

  const { mutate: updatePassword, isLoading } = useUpdatePassword<{
    password: string;
    repeatPassword: string;
    hash: string;
  }>();

  const schema = Yup.object().shape({
    password: Yup.string().required(),
    repeatPassword: Yup.string()
      .test("repeatPassword", "Passwords must match", function (value) {
        return this.parent.password === value;
      })
      .required(),
    hash: Yup.string().required(),
  });

  const form = useForm({
    defaultValues: {
      password: "",
      repeatPassword: "",
      hash: searchParams?.get("hash") as string,
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const { open } = useNotification();

  return (
    <div className={"bg-primary"}>
      <div className={"flex justify-center align-middle items-center h-screen"}>
        <Card className={"md:p-8 py-8 w-full max-w-md mx-2"}>
          <CardBody>
            {searchParams?.get("hash") && (
              <>
                <h1 className={"text-2xl mb-5 text-center"}>Reset password</h1>
                <FormProvider {...form}>
                  <form
                    onSubmit={handleSubmit(
                      (data) => {
                        updatePassword(data);
                      },
                      (error) => {
                        open?.({
                          message: error?.root?.message || "An error occured",
                          type: "error",
                        });
                      }
                    )}
                  >
                    <div className={"flex flex-col gap-4 mb-6"}>
                      <PasswordInput name="password" label={"Enter password"} />
                      <PasswordInput
                        name="repeatPassword"
                        label={"Repeat password"}
                      />
                    </div>
                    <Button
                      size={"lg"}
                      color={"secondary"}
                      type={"submit"}
                      fullWidth={true}
                      isDisabled={isLoading}
                      isLoading={isLoading}
                    >
                      Reset
                    </Button>
                  </form>
                </FormProvider>
              </>
            )}
            {!(searchParams?.get("hash") as string) && (
              <h1 className={"text-xl mb-5 text-center"}>
                The link seems to be broken or expired
              </h1>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

const PasswordInput = (props: { name: string; label: string }) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  const {
    formState: { errors },
    control,
    register,
  } = useFormContext();
  return (
    <Input
      label={props.label}
      variant="bordered"
      isInvalid={!!errors?.[props.name]?.message}
      errorMessage={errors?.[props.name]?.message as string}
      // placeholder="Enter your password"
      endContent={
        <button
          className="focus:outline-none"
          type="button"
          onClick={toggleVisibility}
        >
          {isVisible ? (
            <IconEyeClosed className="text-2xl text-default-400 pointer-events-none" />
          ) : (
            <IconEye className="text-2xl text-default-400 pointer-events-none" />
          )}
        </button>
      }
      type={isVisible ? "text" : "password"}
      className="max-w-sm"
      {...register(props.name)}
      name={props.name}
    ></Input>
  );
};
