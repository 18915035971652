import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  cn,
  Divider,
} from "@nextui-org/react";
import React from "react";
import { useAssociationUserShow } from "../../../context/user-show.context";
import { CpgSponsors } from "./cpg-sponsors";
import { OfficialCgpCardHeader } from "./official-cgp-card-header";
import { UserQrCode } from "./user-qr-code";

export const CardQrCode = ({ isAdminView }: { isAdminView?: boolean }) => {
  const { associationUser } = useAssociationUserShow();
  return (
    <Card
      className={cn(
        "bg-primary w-full rounded-none bg-cover drop-shadow-none shadow-none",
        isAdminView ? "h-full" : "h-screen"
      )}
    >
      <CardHeader className={"p-0 h-24"}>
        <OfficialCgpCardHeader />
        <Divider className={"bg-secondary absolute h-0.5 top-36"}></Divider>
      </CardHeader>
      <CardBody className={"p-0"}>
        {associationUser && (
          <div
            className={"flex justify-center items-center flex-col flex-grow"}
          >
            <div className={"h-[300px] w-[300px] bg-light p-16 rounded-full"}>
              <UserQrCode associationUser={associationUser} />
            </div>
          </div>
        )}
      </CardBody>
      <CardFooter className={"bg-none h-28 p-0 bg-secondary rounded-none"}>
        <CpgSponsors />
      </CardFooter>
    </Card>
  );
};
