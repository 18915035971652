import { useApiUrl } from "@refinedev/core";
import { FileEntity } from "./sdk";

export const useFileEntityUrl = (
  file: FileEntity | number | string | undefined | null
) => {
  const apiUrl = useApiUrl();

  if (!file) {
    return;
  }
  if ((file as FileEntity)?.id) {
    return `${apiUrl}/files/${(file as FileEntity).id}`;
  } else {
    return `${apiUrl}/files/${file}`;
  }
};
