import { useGetIdentity, useNavigation } from "@refinedev/core";
import { useEffect } from "react";
import { useActiveAssociation } from "../../context/active-association.context";
import { User } from "../../sdk";

export const AssociationRedirect = () => {
  const activeAssociation = useActiveAssociation();
  const { data } = useGetIdentity<User>();

  const { replace } = useNavigation();
  useEffect(() => {
    // console.log(activeAssociation + " redirect");
    if (
      // activeAssociation &&
      data?.roles?.some((role) => role.name == "superadmin")
    ) {
      replace(`/association/`);
    } else {
      replace(`/association/${activeAssociation?.id}`);
    }
  }, [activeAssociation]);
  return <div></div>;
};
