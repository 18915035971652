import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React from "react";
import { AssociationUser, User } from "../../../sdk";

export const AddUserToAssociationModal: React.FC<{
  isOpen: boolean;
  onOpenChange: () => void;
  user: User;
  associationUser?: AssociationUser;
  setExistingUser?: (user: User) => void;
}> = ({ isOpen, onOpenChange, user, associationUser, setExistingUser }) => {
  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              User already exists
            </ModalHeader>
            <ModalBody>
              {!associationUser && (
                <>
                  <p>
                    The user{" "}
                    <span className={"text-warning"}>{user?.email}</span>{" "}
                    already has an account. Do you want to add him to your
                    association?
                  </p>
                </>
              )}
              {!!associationUser && (
                <p>
                  The user <span className={"text-warning"}>{user?.email}</span>{" "}
                  is already a member of your association.
                </p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              {!associationUser && (
                <Button
                  color="primary"
                  onPress={async () => {
                    setExistingUser?.(user);
                    onClose();
                  }}
                >
                  Add
                </Button>
              )}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
