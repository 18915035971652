import React, { createContext, ReactNode, useContext, useState } from "react";

export type ToggleDrawerProps = {
  opened?: boolean;
  content?: React.ReactNode;
  headerText?: string;
};
export type DrawerContextType = {
  isDrawerOpen: boolean;
  drawerContent: ReactNode;
  drawerHeader: ReactNode;
  toggleDrawer: (props: ToggleDrawerProps) => void;
  updateDrawerContent: (content: React.ReactNode, opened?: boolean) => void;
  closeDrawer: () => void;
};

const DrawerContext = createContext<DrawerContextType | null>(null);

export const useDrawer = () => useContext(DrawerContext) as DrawerContextType;

export const DrawerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState<ReactNode>(null);
  const [drawerHeader, setDrawerHeader] = useState<ReactNode>(null);

  const toggleDrawer = (props: ToggleDrawerProps) => {
    const opened = props.opened;
    const content = props.content;
    setDrawerContent(content);
    setDrawerHeader(props.headerText);
    setDrawerOpen(typeof opened !== "undefined" ? opened : !isDrawerOpen);
  };

  const updateDrawerContent = (content: ReactNode, opened?: boolean) => {
    setDrawerContent(content);
    toggleDrawer({
      opened,
    });
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <DrawerContext.Provider
      value={{
        isDrawerOpen,
        toggleDrawer,
        closeDrawer,
        updateDrawerContent,
        drawerContent,
        drawerHeader,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
