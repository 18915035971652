/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { PaginatedDocumented } from '../models/PaginatedDocumented';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns User
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody: CreateUserDto,
  }): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public findAll({
    page,
    limit,
    filterNickname,
    filterEmail,
    filterStatus,
    filterRolesId,
    sortBy,
    search,
    searchBy,
  }: {
    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     * <p>
     * <b>Example: </b> 1
     * </p>
     * <p>
     * <b>Default Value: </b> 1
     * </p>
     *
     */
    page?: number,
    /**
     * Number of records per page.
     * <p>
     * <b>Example: </b> 20
     * </p>
     * <p>
     * <b>Default Value: </b> 20
     * </p>
     * <p>
     * <b>Max Value: </b> 100
     * </p>
     *
     * If provided value is greater than max value, max value will be applied.
     *
     */
    limit?: number,
    /**
     * Filter by nickname query param.
     * <p>
     * <b>Format: </b> filter.nickname={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.nickname=$not:$like:John Doe&filter.nickname=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$eq</li>
     * <li>$not</li>
     * <li>$contains</li>
     * <li>$ilike</li>
     * <li>$in</li></ul>
     */
    filterNickname?: Array<string>,
    /**
     * Filter by email query param.
     * <p>
     * <b>Format: </b> filter.email={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.email=$not:$like:John Doe&filter.email=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$eq</li>
     * <li>$not</li>
     * <li>$contains</li>
     * <li>$ilike</li>
     * <li>$in</li></ul>
     */
    filterEmail?: Array<string>,
    /**
     * Filter by status query param.
     * <p>
     * <b>Format: </b> filter.status={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.status=$not:$like:John Doe&filter.status=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$in</li>
     * <li>$eq</li></ul>
     */
    filterStatus?: Array<string>,
    /**
     * Filter by roles.id query param.
     * <p>
     * <b>Format: </b> filter.roles.id={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.roles.id=$not:$like:John Doe&filter.roles.id=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$and</li>
     * <li>$or</li>
     * <li>$not</li>
     * <li>$eq</li>
     * <li>$gt</li>
     * <li>$gte</li>
     * <li>$in</li>
     * <li>$null</li>
     * <li>$lt</li>
     * <li>$lte</li>
     * <li>$btw</li>
     * <li>$ilike</li>
     * <li>$sw</li>
     * <li>$contains</li></ul>
     */
    filterRolesId?: Array<string>,
    /**
     * Parameter to sort by.
     * <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     * <p>
     * <b>Format: </b> fieldName:DIRECTION
     * </p>
     * <p>
     * <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     * </p>
     * <p>
     * <b>Default Value: </b> No default sorting specified, the result order is not guaranteed
     * </p>
     * <h4>Available Fields</h4><ul><li>id</li>
     * <li>firstName</li>
     * <li>lastName</li>
     * <li>status</li>
     * <li>email</li>
     * <li>roles.name</li></ul>
     *
     */
    sortBy?: Array<string>,
    /**
     * Search term to filter result values
     * <p>
     * <b>Example: </b> John
     * </p>
     * <p>
     * <b>Default Value: </b> No default value
     * </p>
     *
     */
    search?: string,
    /**
     * List of fields to search by term to filter result values
     * <p>
     * <b>Example: </b> firstName,email,lastName
     * </p>
     * <p>
     * <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     * </p>
     * <h4>Available Fields</h4><ul><li>firstName</li>
     * <li>email</li>
     * <li>lastName</li></ul>
     *
     */
    searchBy?: string,
  }): CancelablePromise<(PaginatedDocumented & {
    data?: Array<User>;
    meta?: {
      select?: Array<string>;
      filter?: {
        nickname?: (string | Array<string>);
        email?: (string | Array<string>);
        status?: (string | Array<string>);
        'roles.id'?: (string | Array<string>);
      };
    };
  })> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users',
      query: {
        'page': page,
        'limit': limit,
        'filter.nickname': filterNickname,
        'filter.email': filterEmail,
        'filter.status': filterStatus,
        'filter.roles.id': filterRolesId,
        'sortBy': sortBy,
        'search': search,
        'searchBy': searchBy,
      },
    });
  }
  /**
   * @returns User
   * @throws ApiError
   */
  public findOne(): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/{id}',
    });
  }
  /**
   * @returns User
   * @throws ApiError
   */
  public update({
    requestBody,
  }: {
    requestBody: UpdateUserDto,
  }): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/users/{id}',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public remove(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/users/{id}',
    });
  }
}
