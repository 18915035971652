/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeRequest } from '../models/ChangeRequest';
import type { CreateChangeRequestDto } from '../models/CreateChangeRequestDto';
import type { PaginatedDocumented } from '../models/PaginatedDocumented';
import type { UpdateChangeRequestDto } from '../models/UpdateChangeRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ChangeRequestService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns ChangeRequest
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody: CreateChangeRequestDto,
  }): CancelablePromise<ChangeRequest> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/association/{associationId}/change-request',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public findAll({
    page,
    limit,
    filterAssociation,
    filterType,
    filterStatus,
    sortBy,
    search,
    searchBy,
  }: {
    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     * <p>
     * <b>Example: </b> 1
     * </p>
     * <p>
     * <b>Default Value: </b> 1
     * </p>
     *
     */
    page?: number,
    /**
     * Number of records per page.
     * <p>
     * <b>Example: </b> 20
     * </p>
     * <p>
     * <b>Default Value: </b> 20
     * </p>
     * <p>
     * <b>Max Value: </b> 100
     * </p>
     *
     * If provided value is greater than max value, max value will be applied.
     *
     */
    limit?: number,
    /**
     * Filter by association query param.
     * <p>
     * <b>Format: </b> filter.association={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.association=$not:$like:John Doe&filter.association=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
    filterAssociation?: Array<string>,
    /**
     * Filter by type query param.
     * <p>
     * <b>Format: </b> filter.type={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.type=$not:$like:John Doe&filter.type=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
    filterType?: Array<string>,
    /**
     * Filter by status query param.
     * <p>
     * <b>Format: </b> filter.status={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.status=$not:$like:John Doe&filter.status=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
    filterStatus?: Array<string>,
    /**
     * Parameter to sort by.
     * <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     * <p>
     * <b>Format: </b> fieldName:DIRECTION
     * </p>
     * <p>
     * <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     * </p>
     * <p>
     * <b>Default Value: </b> id:DESC
     * </p>
     * <h4>Available Fields</h4><ul><li>id</li>
     * <li>status</li>
     * <li>associationUser.user.firstName</li></ul>
     *
     */
    sortBy?: Array<string>,
    /**
     * Search term to filter result values
     * <p>
     * <b>Example: </b> John
     * </p>
     * <p>
     * <b>Default Value: </b> No default value
     * </p>
     *
     */
    search?: string,
    /**
     * List of fields to search by term to filter result values
     * <p>
     * <b>Example: </b> associationUser.user.firstName,associationUser.user.lastName,associationUser.user.email
     * </p>
     * <p>
     * <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     * </p>
     * <h4>Available Fields</h4><ul><li>associationUser.user.firstName</li>
     * <li>associationUser.user.lastName</li>
     * <li>associationUser.user.email</li></ul>
     *
     */
    searchBy?: string,
  }): CancelablePromise<(PaginatedDocumented & {
    data?: Array<ChangeRequest>;
    meta?: {
      select?: Array<string>;
      filter?: {
        association?: (string | Array<string>);
        type?: (string | Array<string>);
        status?: (string | Array<string>);
      };
    };
  })> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/association/{associationId}/change-request',
      query: {
        'page': page,
        'limit': limit,
        'filter.association': filterAssociation,
        'filter.type': filterType,
        'filter.status': filterStatus,
        'sortBy': sortBy,
        'search': search,
        'searchBy': searchBy,
      },
    });
  }
  /**
   * @returns any[]
   * @throws ApiError
   */
  public findById({
    id,
    relations,
  }: {
    id: number,
    relations?: Array<string>,
  }): CancelablePromise<any[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/association/{associationId}/change-request/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public updateById({
    id,
    requestBody,
    relations,
  }: {
    id: number,
    requestBody: UpdateChangeRequestDto,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/association/{associationId}/change-request/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public deleteById({
    id,
    relations,
  }: {
    id: number,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/association/{associationId}/change-request/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
    });
  }
}
