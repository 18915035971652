import { InputProps } from "@nextui-org/input/dist/input";
import { Input } from "@nextui-org/react";
import React from "react";
import { Control, Controller, FieldValue, FieldValues } from "react-hook-form";

export type FormInputProps = {
  name: string;
  control: Control<any, any>;
} & InputProps;
export const FormInput: React.FC<FormInputProps> = ({ name, ...props }) => {
  return (
    <Controller
      name={name}
      control={props.control}
      render={({ field, fieldState, formState }) => {
        return (
          <Input
            {...props}
            isInvalid={!!formState.errors?.[name]?.message}
            errorMessage={formState.errors?.[name]?.message?.toString()}
            value={field.value}
            onChange={field.onChange}
          />
        );
      }}
    ></Controller>
  );
};
// <Input
//   label="Email"
//   variant={"bordered"}
//   isInvalid={!!errors.email?.message}
//   errorMessage={errors.email?.message}
//   // placeholder={"Enter your email"}
//   {...register("email")}
// />
