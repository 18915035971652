import { CrudSort } from "@refinedev/core";
import React, { useState } from "react";

export const options = {
  title: "Member roles",
  pieHole: 0.4,
  is3D: false,
};

import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
  SortDescriptor,
} from "@nextui-org/react";

export const RolesChart = ({ data }: { data: any }) => {
  const columns = [
    { key: "label", label: "Role" },
    { key: "count", label: "Members" },
  ];

  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "label",
    direction: "descending",
  });

  return (
    <>
      <div className={"flex flex-row max-w-lg"}>
        <Table
          aria-label="Example table with dynamic content"
          sortDescriptor={sortDescriptor}
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody
            items={data
              .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
              .map((el: any) => {
                return { key: el.name, label: el.name, count: el.count };
              })}
          >
            {(item: any) => (
              <TableRow key={item.key}>
                {(columnKey) => (
                  <TableCell key={`${item.key}_${columnKey}`}>
                    {getKeyValue(item, columnKey)}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
