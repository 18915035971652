import {
  Button,
  cn,
  Spacer,
  Ripple,
  useRipple,
  Image,
  Accordion,
  AccordionItem,
} from "@nextui-org/react";
import {
  useGetIdentity,
  useLogout,
  useMenu,
  useTranslate,
} from "@refinedev/core";
import { TreeMenuItem } from "@refinedev/core/dist/hooks/menu/useMenu";
import { IconHelpCircle, IconLogout, IconMenu } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useActiveAssociation } from "../../context/active-association.context";
import { User } from "../../sdk";
export const Sidebar: React.FC = () => {
  const activeAssociation = useActiveAssociation();
  const { menuItems, selectedKey } = useMenu({
    meta: {
      associationId: activeAssociation?.id,
    },
  }); // Utilize Refine's headless core

  const [isOpen, setIsOpen] = useState(false);
  const { data: user } = useGetIdentity<User>();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    setRoles(user?.roles?.map?.((role) => role.name) || []);
  }, [user]);

  const { mutate: logout } = useLogout();
  useEffect(() => {
    setIsOpen(false);
  }, [selectedKey]);

  return (
    <div
      className={cn(
        "flex flex-row w-full bg-primary mb-20 md:mb-0",
        isOpen ? "bg-none" : "bg-primary"
      )}
    >
      <div
        className={cn(
          "flex flex-row justify-between w-full py-2 px-2 md:hidden z-50 fixed top-0 items-center align-middle",
          isOpen ? "" : "bg-primary"
        )}
      >
        <Image
          src={`${
            import.meta.env.VITE_PUBLIC_BACKEND_API_BASE_URL
          }/cpg-logo.png`}
          className={"object-cover h-10"}
        />

        <Button
          variant={"ghost"}
          color={"secondary"}
          data-drawer-target="default-sidebar"
          data-drawer-toggle="default-sidebar"
          aria-controls="default-sidebar"
          type="button"
          isIconOnly={true}
          className="inline-flex items-center p-2 ms-3 text-sm text-primary-foreground rounded-lg md:hidden hover:bg-secondary"
          onPress={() => setIsOpen(!isOpen)}
        >
          <span className="sr-only">Open sidebar</span>
          <IconMenu />
        </Button>
      </div>

      <aside
        id="default-sidebar"
        className={cn(
          "fixed top-0 left-0 z-40 w-full md:w-72 h-screen transition-transform md:translate-y-0 md:translate-x-0",
          isOpen ? "translate-x-0 translate-y-0" : "-translate-y-full"
        )}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))]  from-primary via-primary-shade to-primary dark:bg-gray-800 ">
          <div className={"hidden justify-center my-10 h-20 md:flex"}>
            <Image
              src={`${
                import.meta.env.VITE_PUBLIC_BACKEND_API_BASE_URL
              }/cpg-logo.png`}
              className={"object-cover h-32"}
            />
          </div>
          <div aria-label="Sidebar" className={"mt-16 flex flex-col"}>
            {menuItems
              .filter(
                (el) =>
                  !el?.meta?.roles ||
                  el?.meta?.roles?.some((role: string) =>
                    roles?.includes?.(role)
                  )
              )
              .map((treeItem, index) => (
                // <SidebarLink treeItem={treeItem} />
                // <Link to={treeItem.route} key={treeItem.key} as={NavLink}>
                //   {treeItem.meta?.label}
                <TreeItem treeItem={treeItem} key={index} />
              ))}
          </div>

          <a
            href={"https://magical-trifle-9af43b.netlify.app"}
            target={"_blank"}
          >
            <div
              className={
                "hover:text-light hover:bg-secondary  rounded-2xl flex my-1 py-4 px-8 mx-4 text-light md:mt-60 mt-20"
              }
            >
              <IconHelpCircle className={"mr-2"} />
              Documentation
            </div>
          </a>

          <div
            className={
              "hover:text-light hover:bg-secondary  rounded-2xl flex my-1 py-2 px-8 mx-4 bg-light flex md:hidden mt-2 text-danger"
            }
            onClick={() => {
              logout();
              setIsOpen(false);
            }}
          >
            <IconLogout className={"mr-2"} />
            Logout
          </div>
        </div>
      </aside>
    </div>
  );
};

type SidebarTreeItemProps = {
  treeItem: TreeMenuItem;
};
export const TreeItem: React.FC<SidebarTreeItemProps> = ({ treeItem }) => {
  return (
    <>
      {treeItem.children && treeItem.children.length > 0 ? (
        <TreeItemAccordion treeItem={treeItem} />
      ) : (
        <TreeItemLink treeItem={treeItem} />
      )}
    </>
  );
};

const TreeItemLink = ({ treeItem }: { treeItem: TreeMenuItem }) => {
  const rippleProps = useRipple({});
  const { selectedKey } = useMenu();
  return (
    <NavLink
      to={treeItem.route!}
      key={treeItem.key}
      className={({ isActive, isPending }) => {
        const base = `hover:text-light hover:bg-secondary  rounded-2xl flex my-1 py-4 px-8 items-center`;
        const layout = treeItem.meta?.parent ? "mx-4" : "mx-4";

        if (selectedKey === treeItem.key) {
          return cn(base, layout, "bg-light text-dark");
        }
        return cn(base, layout, "rounded-2xl text-light");
      }}
    >
      <Ripple {...rippleProps} />
      {treeItem.meta?.icon && (
        <>
          {treeItem.meta?.icon}
          <Spacer x={1.5} />
        </>
      )}
      {treeItem.meta?.label}
    </NavLink>
  );
};

const TreeItemAccordion = ({ treeItem }: { treeItem: TreeMenuItem }) => {
  return (
    <Accordion
      isCompact={false}
      className={"text-light rounded-2xl mx-4 px-0 flex w-auto"}
      itemClasses={{
        base: "w-full",
        title: "font-normal text-medium text-light",
        trigger: "px-8 rounded-2xl hover:bg-secondary flex w-full",
        // "py-0 data-[hover=true]:bg-secondary r ounded-2xl items-center bg-opacity-0 px-8 mx-4 py-4",
        indicator: "",
        content: "w-full",
        titleWrapper: "bg-none data-[hover=true]:text-secondary w-full",
      }}
    >
      <AccordionItem
        key={treeItem.key}
        title={treeItem?.meta?.label}
        startContent={treeItem?.meta?.icon}
      >
        {treeItem.children?.map((el) => (
          // <TreeItem key={`treeItem_${el.key}`} treeItem={el} />
          <div key={`span_${el.key}`}>
            <TreeItemLink treeItem={el} />
          </div>
        ))}
      </AccordionItem>
    </Accordion>
  );
};
