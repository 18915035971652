import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  SelectItem,
} from "@nextui-org/react";
import {
  useCreate,
  useNavigation,
  useNotification,
  useOne,
  useUpdate,
} from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import React, { HTMLProps, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { ImageDropzone } from "../../components/image-dropzone/image-dropzone";
import { FormInput } from "../../components/ui/form/form-input";
import { useActiveAssociation } from "../../context/active-association.context";
import { useDrawer } from "../../context/drawer.context";
import { useFileEntityUrl } from "../../hooks";
import { ApiSdk } from "../../lib/api-sdk";
import { ResourceIdentifier } from "../../resources";

export const AssociationEdit = ({ isCreate }: { isCreate?: boolean }) => {
  const association = useActiveAssociation();

  let [files, setFiles] = React.useState<null | File[]>(null);

  const schema = yup.object().shape({
    name: yup.string().required(),
    phoneNumber: yup.string().nullable().notRequired(),
    email: yup.string().email().nullable(),
    logo: yup.string().nullable(),
    // logoId: yup.string().nullable(),
    yearFormed: yup.number().moreThan(1800).lessThan(2030).nullable(),
    courses: yup.number().nullable(),
    website: yup.string().url().nullable(),
    country: yup.string().nullable(),
    address: yup.string().nullable(),
    zip: yup.string().nullable(),
    city: yup.string().nullable(),
  });

  const form = useForm({
    defaultValues: {
      name: "",
      courses: 0,
      phoneNumber: "",
      email: "",
      // logo: null,
      // logoId: null,
      yearFormed: 0,
      website: "",
      country: "",
      address: "",
      zip: "",
      city: "",
    },
    values: {
      name: (!isCreate && association?.name) || "",
      courses: !isCreate ? association?.courses : undefined,
      email: !isCreate ? association?.email : undefined,
      // logo: !isCreate ? association?.logoId : undefined,
      phoneNumber: !isCreate ? association?.phoneNumber : undefined,
      yearFormed: !isCreate ? association?.yearFormed : undefined,
      website: !isCreate ? association?.website : undefined,
      country: !isCreate ? association?.country : undefined,
      address: !isCreate ? association?.address : undefined,
      zip: !isCreate ? association?.zip : undefined,
      city: !isCreate ? association?.city : undefined,
    },
    resolver: yupResolver(schema),
    refineCoreProps: {
      resource: `association/${association?.id}`,
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const { closeDrawer } = useDrawer();
  const [loading, setLoading] = React.useState(false);

  const { open } = useNotification();
  const { mutateAsync: updateAssociation } = useUpdate();
  const { mutateAsync: createAssociation } = useCreate();

  const onSuccess = () => {
    closeDrawer();
  };

  const onError = (e: any) => {
    open?.({
      message: "Error occured while creating association",
      type: "error",
    });
  };

  const onSettled = () => {
    setLoading(false);
  };

  const upsertAssociation = async (data: yup.InferType<typeof schema>) => {
    // if (files?.[0]) {
    //   try {
    //     // const fileEntity = await ApiSdk.files.uploadFile({
    //     //   formData: {
    //     //     file: files[0],
    //     //   },
    //     // });
    //     // data.logo = fileEntity?.id;
    //   } catch (e) {
    //     open?.({
    //       message: "Error occured while uploading file",
    //       type: "error",
    //     });
    //   }
    // }

    setLoading(true);
    if (isCreate) {
      await createAssociation(
        {
          resource: ResourceIdentifier.Association,
          values: data,
          successNotification: {
            message: "Association created successfully",
            type: "success",
          },
          errorNotification: {
            message: "Error creating association",
            type: "error",
          },
        },
        {
          onSuccess,
          onError,
          onSettled,
        }
      );
    } else {
      await updateAssociation(
        {
          resource: ResourceIdentifier.Association,
          id: association?.id!,
          values: data,
          successNotification: {
            message: "Association updated successfully",
            type: "success",
          },
          errorNotification: {
            message: "Error updating association",
            type: "error",
          },
        },
        {
          onSuccess,
          onError,
          onSettled,
        }
      );
    }
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(upsertAssociation)}>
        <div className={"flex flex-col gap-4 mb-6 max-w-lg"}>
          {/*<div className={"flex flex-col gap-4 mb-6 max-w-lg"}>*/}
          {/*  <ImageDropzone*/}
          {/*    files={files}*/}
          {/*    existingImageUrl={useFileEntityUrl(association?.logoId)}*/}
          {/*    setFiles={setFiles}*/}
          {/*    onClear={() => {*/}
          {/*      form.setValue("logo", null);*/}
          {/*    }}*/}
          {/*    label={"Select the association logo"}*/}
          {/*  />*/}
          {/*</div>*/}
          <FormInput
            name={"name"}
            label={"Name"}
            variant={"bordered"}
            control={control}
          />
          <FormInput
            name={"country"}
            label={"Country"}
            variant={"bordered"}
            control={control}
          />
          <div className={"flex flex-col gap-4 md:flex-row"}>
            <FormInput
              name={"phoneNumber"}
              label={"Phone number"}
              variant={"bordered"}
              control={control}
            />
            <FormInput
              name={"email"}
              label={"Email"}
              variant={"bordered"}
              control={control}
            />
          </div>
          <div className={"flex flex-col gap-4 md:flex-row"}>
            <FormInput
              name={"yearFormed"}
              label={"Year formed"}
              variant={"bordered"}
              control={control}
            />
            <FormInput
              name={"website"}
              label={"Website"}
              variant={"bordered"}
              control={control}
            />
          </div>

          <div className={"flex flex-col gap-4 md:flex-row"}>
            <FormInput
              name={"courses"}
              label={"Courses"}
              variant={"bordered"}
              control={control}
            />

            <FormInput
              name={"address"}
              label={"Address"}
              variant={"bordered"}
              control={control}
            />
          </div>
          <div className={"flex flex-col gap-4 md:flex-row"}>
            <FormInput
              name={"city"}
              label={"City"}
              variant={"bordered"}
              control={control}
            />{" "}
            <FormInput
              name={"zip"}
              label={"ZIP"}
              variant={"bordered"}
              control={control}
            />
          </div>
        </div>
        <Button
          size={"lg"}
          color={"secondary"}
          type={"submit"}
          fullWidth={true}
          isLoading={loading}
          isDisabled={loading}
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
};
