import React from "react";
import { useDrawer } from "../../context/drawer.context";
import Drawer from "./drawer";

const GlobalDrawer: React.FC = () => {
  const {
    isDrawerOpen,
    drawerContent,
    toggleDrawer,
    closeDrawer,
    drawerHeader,
  } = useDrawer();

  return (
    <Drawer
      isOpen={isDrawerOpen}
      onOpenChange={() => {
        closeDrawer();
      }}
      header={drawerHeader}
      className={`fixed top-0 left-0 h-full z-50 transition-transform ${
        isDrawerOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      {drawerContent}
    </Drawer>
  );
};

export default GlobalDrawer;
