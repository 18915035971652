/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssociationUser } from '../models/AssociationUser';
import type { CreateAssociationUserDto } from '../models/CreateAssociationUserDto';
import type { PaginatedDocumented } from '../models/PaginatedDocumented';
import type { UpdateAssociationUserDto } from '../models/UpdateAssociationUserDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AssociationUserService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns AssociationUser
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody: CreateAssociationUserDto,
  }): CancelablePromise<AssociationUser> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/association/{associationId}/user',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public findAll({
    page,
    limit,
    filterAssociation,
    filterUserEmail,
    filterRole,
    sortBy,
    search,
    searchBy,
  }: {
    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     * <p>
     * <b>Example: </b> 1
     * </p>
     * <p>
     * <b>Default Value: </b> 1
     * </p>
     *
     */
    page?: number,
    /**
     * Number of records per page.
     * <p>
     * <b>Example: </b> 20
     * </p>
     * <p>
     * <b>Default Value: </b> 20
     * </p>
     * <p>
     * <b>Max Value: </b> 100
     * </p>
     *
     * If provided value is greater than max value, max value will be applied.
     *
     */
    limit?: number,
    /**
     * Filter by association query param.
     * <p>
     * <b>Format: </b> filter.association={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.association=$not:$like:John Doe&filter.association=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
    filterAssociation?: Array<string>,
    /**
     * Filter by user.email query param.
     * <p>
     * <b>Format: </b> filter.user.email={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.user.email=$not:$like:John Doe&filter.user.email=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
    filterUserEmail?: Array<string>,
    /**
     * Filter by role query param.
     * <p>
     * <b>Format: </b> filter.role={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.role=$not:$like:John Doe&filter.role=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
    filterRole?: Array<string>,
    /**
     * Parameter to sort by.
     * <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     * <p>
     * <b>Format: </b> fieldName:DIRECTION
     * </p>
     * <p>
     * <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     * </p>
     * <p>
     * <b>Default Value: </b> id:DESC
     * </p>
     * <h4>Available Fields</h4><ul><li>id</li>
     * <li>user.email</li>
     * <li>user.firstName</li>
     * <li>user.lastName</li>
     * <li>associationRole.name</li></ul>
     *
     */
    sortBy?: Array<string>,
    /**
     * Search term to filter result values
     * <p>
     * <b>Example: </b> John
     * </p>
     * <p>
     * <b>Default Value: </b> No default value
     * </p>
     *
     */
    search?: string,
    /**
     * List of fields to search by term to filter result values
     * <p>
     * <b>Example: </b> user.email,user.firstName,user.lastName
     * </p>
     * <p>
     * <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     * </p>
     * <h4>Available Fields</h4><ul><li>user.email</li>
     * <li>user.firstName</li>
     * <li>user.lastName</li></ul>
     *
     */
    searchBy?: string,
  }): CancelablePromise<(PaginatedDocumented & {
    data?: Array<AssociationUser>;
    meta?: {
      select?: Array<string>;
      filter?: {
        association?: (string | Array<string>);
        'user.email'?: (string | Array<string>);
        role?: (string | Array<string>);
      };
    };
  })> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/association/{associationId}/user',
      query: {
        'page': page,
        'limit': limit,
        'filter.association': filterAssociation,
        'filter.user.email': filterUserEmail,
        'filter.role': filterRole,
        'sortBy': sortBy,
        'search': search,
        'searchBy': searchBy,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public updateById({
    id,
    requestBody,
    relations,
  }: {
    id: number,
    requestBody: UpdateAssociationUserDto,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/association/{associationId}/user/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns AssociationUser
   * @throws ApiError
   */
  public getOne({
    id,
    relations,
  }: {
    id: number,
    relations?: Array<string>,
  }): CancelablePromise<AssociationUser> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/association/{associationId}/user/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public deleteById({
    id,
    relations,
  }: {
    id: number,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/association/{associationId}/user/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public countUsers(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/association/{associationId}/user/aggregate/count',
    });
  }
}
