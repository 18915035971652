import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Image,
  Spacer,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from "@nextui-org/react";
import {
  CrudSort,
  IResourceComponentsProps,
  useDelete,
  useOne,
  useShow,
  useTable,
} from "@refinedev/core";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MembershipList } from "../../../components/membership/memebership-list";
import { TableTopContent } from "../../../components/table/table-top-content";
import { ConfirmationModal } from "../../../components/ui/confirmation-modal";
import { useActiveAssociation } from "../../../context/active-association.context";
import { useDrawer } from "../../../context/drawer.context";
import {
  AssociationUserShowContext,
  AssociationUserShowProvider,
  useAssociationUserShow,
} from "../../../context/user-show.context";
import { useFileEntityUrl } from "../../../hooks";
import { getCountryFlagUrl } from "../../../lib/flag-map";
import { ResourceIdentifier } from "../../../resources";
import { AssociationUser, Certificate } from "../../../sdk";
import { UpsertCertificateForm } from "../../certificate/upsert-certificate-form";
import { UserCards } from "../../public-user-view/user-cards";
import { Card1 } from "./card-1";
import { Card2 } from "./card-2";
import { Card3 } from "./card-3";

export const MemberView: React.FC<IResourceComponentsProps> = () => {
  const activeAssociation = useActiveAssociation();

  const params = useParams();
  const { queryResult } = useShow<AssociationUser>({
    resource: `association/${activeAssociation?.id}/user`,
    id: params.id,
  });
  const { data: associationUser, isLoading } = queryResult;

  return (
    <>
      {associationUser?.data?.id && (
        <AssociationUserShowProvider associationUser={associationUser?.data}>
          <MembershipList />
          <Spacer y={20} />

          <UserCards isAdminView={true} />
        </AssociationUserShowProvider>
      )}
    </>
  );
};
