/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Association } from '../models/Association';
import type { CreateAssociationDto } from '../models/CreateAssociationDto';
import type { PaginatedDocumented } from '../models/PaginatedDocumented';
import type { UpdateAssociationDto } from '../models/UpdateAssociationDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AssociationService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns Association
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody: CreateAssociationDto,
  }): CancelablePromise<Association> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/association',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public findAll({
    page,
    limit,
    filterCountry,
    sortBy,
    search,
    searchBy,
  }: {
    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     * <p>
     * <b>Example: </b> 1
     * </p>
     * <p>
     * <b>Default Value: </b> 1
     * </p>
     *
     */
    page?: number,
    /**
     * Number of records per page.
     * <p>
     * <b>Example: </b> 20
     * </p>
     * <p>
     * <b>Default Value: </b> 10
     * </p>
     * <p>
     * <b>Max Value: </b> 100
     * </p>
     *
     * If provided value is greater than max value, max value will be applied.
     *
     */
    limit?: number,
    /**
     * Filter by country query param.
     * <p>
     * <b>Format: </b> filter.country={$not}:OPERATION:VALUE
     * </p>
     * <p>
     * <b>Example: </b> filter.country=$not:$like:John Doe&filter.country=like:John
     * </p>
     * <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
    filterCountry?: Array<string>,
    /**
     * Parameter to sort by.
     * <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     * <p>
     * <b>Format: </b> fieldName:DIRECTION
     * </p>
     * <p>
     * <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     * </p>
     * <p>
     * <b>Default Value: </b> id:DESC
     * </p>
     * <h4>Available Fields</h4><ul><li>id</li>
     * <li>name</li>
     * <li>country</li></ul>
     *
     */
    sortBy?: Array<string>,
    /**
     * Search term to filter result values
     * <p>
     * <b>Example: </b> John
     * </p>
     * <p>
     * <b>Default Value: </b> No default value
     * </p>
     *
     */
    search?: string,
    /**
     * List of fields to search by term to filter result values
     * <p>
     * <b>Example: </b> name,country
     * </p>
     * <p>
     * <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     * </p>
     * <h4>Available Fields</h4><ul><li>name</li>
     * <li>country</li></ul>
     *
     */
    searchBy?: string,
  }): CancelablePromise<(PaginatedDocumented & {
    data?: Array<Association>;
    meta?: {
      select?: Array<string>;
      filter?: {
        country?: (string | Array<string>);
      };
    };
  })> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/association',
      query: {
        'page': page,
        'limit': limit,
        'filter.country': filterCountry,
        'sortBy': sortBy,
        'search': search,
        'searchBy': searchBy,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public updateById({
    id,
    requestBody,
    relations,
  }: {
    id: number,
    requestBody: UpdateAssociationDto,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/association/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Association
   * @throws ApiError
   */
  public getOne({
    id,
    relations,
  }: {
    id: number,
    relations?: Array<string>,
  }): CancelablePromise<Association> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/association/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public deleteById({
    id,
    relations,
  }: {
    id: number,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/association/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
    });
  }
}
