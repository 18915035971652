import { Button, Spacer } from "@nextui-org/react";
import { useCustom, useInvalidate, useTable } from "@refinedev/core";
import React from "react";
import { useActiveAssociation } from "../../../context/active-association.context";
import { ResourceIdentifier } from "../../../resources";
import { AssociationGolfClasses } from "../association-golf-class/association-golf-class";
import { AssociationRoles } from "../association-role/association-roles";
import { AssociationInfo } from "./association-info";
import { RolesChart } from "./roles-chart";
import { TopCardsRow } from "./top-cards-row";

export const AssociationDashboard: React.FC = () => {
  const association = useActiveAssociation();

  const { data: count } = useCustom({
    url: `${import.meta.env.VITE_PUBLIC_BACKEND_API_URL}/association/${
      association?.id
    }/user/aggregate/count`,
    method: "get",
    queryOptions: {
      enabled: !!association,
    },
  });

  return (
    <>
      <div className={"flex flex-col w-full"}>
        {/*<TopCardsRow data={count} />*/}
        <Spacer y={8} />
        <div>
          <AssociationInfo />
        </div>
      </div>
      <Spacer y={8} />
      {count?.data && <RolesChart data={count.data} />}
      <div
        className={"flex md:flex-row md:flex-nowrap flex-col flex-wrap gap-12"}
      >
        {/*<AssociationRoles />*/}
        {/*<AssociationGolfClasses />*/}
        {/*<AssociationCertificates />*/}
        {/*<AssociationCoachClasses />*/}
      </div>
    </>
  );
};
