export const getCountryAlpha2Code = (country?: string) => {
  if (!country) {
    return;
  }
  const countryMap = {
    al: ["albania", "shqipëria"],
    de: ["germany", "deutschland"],
    si: ["slovenia", "slovenija"],
    it: ["italy", "italia"],
    ne: ["netherlands", "nederland", "holland"],
    fr: ["france"],
    at: ["austria", "österreich"],
    ch: ["switzerland", "schweiz"],
    hr: ["croatia", "hrvatska"],
    cz: ["czech republic", "česká republika"],
    sk: ["slovakia", "slovensko"],
    hu: ["hungary", "magyarország"],
    pl: ["poland", "polska"],
    be: ["belgium", "belgique"],
    lu: ["luxembourg"],
    dk: ["denmark", "danmark"],
    se: ["sweden", "sverige"],
    mt: ["malta"],
    jm: ["jamaica"],
    is: ["iceland", "ísland"],
  };

  const lowercasedCountry = country.toLowerCase();
  for (const [key, value] of Object.entries(countryMap)) {
    if (value.includes(lowercasedCountry)) {
      return key;
    }
  }
};

export const getCountryFlagUrl = (country?: string) => {
  return `${
    import.meta.env.VITE_PUBLIC_BACKEND_API_BASE_URL
  }/flags/${getCountryAlpha2Code(country)}.png`;
};
