import { createContext, FC, useContext, useEffect, useState } from "react";
import { AssociationUser, User } from "../sdk";

export const AssociationUserShowContext = createContext<{
  associationUser: AssociationUser | undefined;
  // setAssociationUser: (associationUser: AssociationUser | null) => void;
}>({ associationUser: undefined });

interface Props {
  children: React.ReactNode;
  associationUser?: AssociationUser;
}

export const AssociationUserShowProvider: FC<Props> = ({
  children,
  associationUser,
}) => {
  return (
    <AssociationUserShowContext.Provider value={{ associationUser }}>
      {children}
    </AssociationUserShowContext.Provider>
  );
};

export const useAssociationUserShow = () => {
  return useContext(AssociationUserShowContext);
};
