import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@nextui-org/react";
import { useCreate, useInvalidate, useUpdate } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { isDate } from "lodash";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useActiveAssociation } from "../../context/active-association.context";
import { useDrawer } from "../../context/drawer.context";
import { useAssociationUserShow } from "../../context/user-show.context";
import { ResourceIdentifier } from "../../resources";
import { AssociationMembership } from "../../sdk";
import { FormDatePicker } from "../ui/form/form-datepicker";
import { format, formatDistance, formatRelative, subDays } from "date-fns";

export const UpsertMembershipForm: React.FC<{
  membership?: AssociationMembership;
}> = ({ membership }) => {
  const { closeDrawer } = useDrawer();
  const association = useActiveAssociation();
  const { id: associationUserId } = useParams();
  // const { associationUser } = useAssociationUserShow();

  const schema = yup.object().shape({
    startDate: yup.date().typeError("Start date is required").required(),
    endDate: yup
      .date()
      .typeError("End date is required")
      .required()
      .min(yup.ref("startDate"), "end date can't be before start date"),
  });

  function getDefaultDateValue(date?: string) {
    if (!date) return "";

    return format(date, "yyyy-MM-dd");
  }

  const form = useForm({
    defaultValues: {
      startDate: "" as any,
      endDate: "" as any,
    },
    values: {
      startDate: getDefaultDateValue(membership?.startDate) as any,
      endDate: getDefaultDateValue(membership?.endDate) as any,
    },
    resolver: yupResolver(schema),
    refineCoreProps: {
      resource: ResourceIdentifier.Membership,
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const [loading, setLoading] = React.useState(false);

  const { mutateAsync: updateAssociationMembership } = useUpdate();
  const { mutateAsync: createAssociationMembership } = useCreate();
  const invalidate = useInvalidate();
  const onSuccess = () => {
    closeDrawer();
  };

  const onSettled = async () => {
    setLoading(false);
    await invalidate({
      resource: ResourceIdentifier.Membership,
      invalidates: ["all"],
    });
    await invalidate({
      resource: ResourceIdentifier.UserMembership,
      invalidates: ["all"],
    });
  };
  const upsert = async (data: yup.InferType<typeof schema>) => {
    setLoading(true);
    const resource = ResourceIdentifier.Membership;
    const upsertArgs = {
      resource,
      meta: {
        associationId: association?.id,
      },
      values: {
        ...data,
        association: {
          id: association?.id,
        },
        associationUser: {
          id: associationUserId,
        },
      },
      successNotification: {
        message: "Association membership created successfully",
        type: "success" as const,
      },
      errorNotification: (error: any) => {
        console.log("ERROR ", error);
        return {
          message: "Error updating association membership",
          description: error?.message,
          type: "error" as const,
          position: "top-center",
        };
      },
    };

    if (!membership?.id) {
      await createAssociationMembership(
        {
          ...upsertArgs,
        },
        {
          onSuccess: onSuccess,
          onSettled: onSettled,
        }
      );
    } else {
      await updateAssociationMembership(
        {
          ...upsertArgs,
          id: membership?.id!,
          successNotification: {
            message: "Association membership updated successfully",
            type: "success" as const,
          },
        },
        {
          onSuccess: onSuccess,
          onSettled: onSettled,
        }
      );
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(upsert)}>
        <div className={"grid grid-cols-1 gap-4 mb-6"}>
          <FormDatePicker
            name={"startDate"}
            label={"Start date"}
            variant={"bordered"}
            control={control}
          />

          <FormDatePicker
            name={"endDate"}
            label={"End date"}
            variant={"bordered"}
            control={control}
          />
        </div>
        <Button
          fullWidth
          size={"lg"}
          color={"secondary"}
          type={"submit"}
          isLoading={loading}
          isDisabled={loading}
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
};
