import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  cn,
  Divider,
  Image,
} from "@nextui-org/react";
import React from "react";
import { useAssociationUserShow } from "../../../context/user-show.context";
import { useFileEntityUrl } from "../../../hooks";
import { getCountryFlagUrl } from "../../../lib/flag-map";
import { BackgroundNumbers } from "./background-numbers";
import { OfficialCgpCardHeader } from "./official-cgp-card-header";

export const Card2 = ({ isAdminView }: { isAdminView?: boolean }) => {
  return (
    <Card
      className={cn(
        "bg-primary w-full rounded-none",
        isAdminView ? "h-full" : "h-screen"
      )}
    >
      <CardHeader className={"p-0 h-32 rounded-none"}>
        <OfficialCgpCardHeader />
        {/*<HeaderImage />*/}
        <CountryFlag />
      </CardHeader>
      <CardBody className={"bg-light overflow-hidden p-0 mt-4"}>
        <BackgroundNumbers textColor={"text-background"} />
        <div className={"grid grid-cols-1 mt-20 mb-12"}>
          <UserInfoName />
        </div>
      </CardBody>
      <CardFooter className={"bg-none h-28 p-0 bg-secondary rounded-none"}>
        {/*<FooterContents />*/}
      </CardFooter>
    </Card>
  );
};

const CountryFlag: React.FC = () => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div className={"flex justify-center z-10 w-full relative"}>
      <Divider className={"bg-secondary absolute h-0.5 top-20"}></Divider>
      <div
        className={
          "w-32 h-44 text-large border-secondary absolute rounded-full flex flex-col justify-center"
        }
      >
        <Image
          src={getCountryFlagUrl(associationUser?.association?.country)}
          height={180}
          width={180}
        />
      </div>
    </div>
  );
};

const UserInfoName: React.FC = ({}) => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div className={"flex justify-center my-3 z-10"}>
      <div className={"flex flex-col justify-center items-center"}>
        <div
          className={
            "text-2xl font-light text-dark/90 font-serif text-center leading-tight tracking-wide"
          }
        >
          {associationUser?.user?.firstName === "female" ? "Ms." : "Mr."}{" "}
          {associationUser?.user?.firstName} {associationUser?.user?.lastName}
        </div>
        <div>
          <Divider className={"h-2 text-secondary bg-secondary my-5"} />
        </div>
        <div className={"relative"}>
          <div
            className={
              "flex flex-col text-center  text-primary text-md font-semibold uppercase tracking-widest mt-0 mb-8"
            }
          >
            Date of birth
            <span className={"font-normal"}>
              {associationUser?.user?.dateOfBirth &&
                new Date(
                  associationUser?.user?.dateOfBirth
                ).toLocaleDateString()}
            </span>
          </div>

          <div className={"mt-5 flex flex-col text-center "}>
            <span className={"font-semibold text-md uppercase tracking-widest"}>
              Class
            </span>
            <span className={"text-3xl font-light"}>
              {associationUser?.associationGolfClass?.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const FooterContents = () => {
  return (
    <div
      className={
        "bg-content3 h-full w-full border-t-secondary border-t-2 flex flex-row justify-around gap-x-10 p-5 items-center"
      }
    >
      <Image
        className={"h-10 bg-none"}
        src={"/images/golfbidder.svg"}
        height={30}
      />

      <Image
        className={"h-10"}
        src={"https://eu.ping.com/web/assets/media/images/logo.svg"}
        height={30}
      />

      <Image
        className={"h-10"}
        height={30}
        src={"https://www.trackman.com/Trackman_logo--dark.svg"}
      />
    </div>
  );
};
