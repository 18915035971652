/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileEntity } from '../models/FileEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FilesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns FileEntity
   * @throws ApiError
   */
  public uploadFile({
    formData,
  }: {
    formData: {
      file?: Blob;
    },
  }): CancelablePromise<FileEntity> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/files/upload',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public download({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/{id}',
      path: {
        'id': id,
      },
    });
  }
}
