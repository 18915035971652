import { ChipProps } from "@nextui-org/chip/dist/chip";
import { Chip, cn } from "@nextui-org/react";
import {
  IconDots,
  IconSquareCheckFilled,
  IconSquareXFilled,
} from "@tabler/icons-react";
import React, { ReactElement } from "react";
import { ChangeRequest } from "../../sdk";

export const StatusBadge = ({
  changeRequest,
  chipProps,
}: {
  changeRequest: ChangeRequest;
  chipProps?: ChipProps;
}) => {
  return changeRequest.status === "approved" ? (
    <StatusApprovedBadge chipProps={chipProps} />
  ) : changeRequest.status === "rejected" ? (
    <StatusRejectedBadge chipProps={chipProps} />
  ) : (
    <StatusPendingBadge chipProps={chipProps} />
  );
};

const StatusPendingBadge = ({ chipProps }: { chipProps?: ChipProps }) => {
  return (
    <StatusBadgeBase
      status={"Pending"}
      icon={<IconDots className={"w-4 mr-1"} />}
      chipProps={{ ...chipProps, color: "warning" }}
    />
  );
};

const StatusRejectedBadge = ({ chipProps }: { chipProps?: ChipProps }) => {
  return (
    <StatusBadgeBase
      status={"Rejected"}
      icon={<IconSquareXFilled className={"w-4 mr-1"} />}
      chipProps={{ ...chipProps, color: "danger" }}
    />
  );
};

const StatusApprovedBadge = ({ chipProps }: { chipProps?: ChipProps }) => {
  return (
    <StatusBadgeBase
      status={"Approved"}
      icon={<IconSquareCheckFilled className={"w-4 mr-1"} />}
      chipProps={{ ...chipProps, color: "success" }}
    />
  );
};

const StatusBadgeBase = ({
  status,
  icon,
  chipProps,
}: {
  status: string;
  icon: ReactElement;
  chipProps: ChipProps;
}) => {
  return (
    <Chip
      {...chipProps}
      endContent={icon}
      size={"md"}
      className={cn("px-2", chipProps.className)}
    >
      <span>{status}</span>
    </Chip>
    // <span
    //   className={cn(
    //     "rounded-full flex flex-row items-center justify-center gap-x-1 max-w-xs",
    //     sizeMap,
    //     className
    //   )}
    // >
    //   <span>{status}</span>
    //   {icon}
    // </span>
  );
};
