import { PostHogProvider } from "posthog-js/react";

import * as Sentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";
import { httpClientIntegration } from "@sentry/integrations";

import React, { FC, ReactElement } from "react";

Sentry.init({
  dsn: import.meta.env.VITE_PUBLIC_SENTRY_DSN,
  enabled: !import.meta.env.VITE_PUBLIC_BACKEND_API_URL?.includes("localhost"),
  integrations: [
    captureConsoleIntegration({ levels: ["error"] }),
    httpClientIntegration({
      failedRequestStatusCodes: [
        400, 401, 403, 404, 422, 429, 500, 502, 503, 504,
      ],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    import.meta.env.VITE_PUBLIC_BACKEND_API_URL,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const GlobalProviders: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const postHogOptions = {
    api_host: `${import.meta.env.VITE_PUBLIC_POSTHOG_HOST}`,
  };
  return !import.meta.env.VITE_PUBLIC_BACKEND_API_URL?.includes("localhost") ? (
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={{ ...postHogOptions }}
    >
      {children}
    </PostHogProvider>
  ) : (
    <>{children}</>
  );
};
