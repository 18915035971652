import React, { ReactNode } from "react";
import { NotificationProvider } from "@refinedev/core";
import { toast } from "react-toastify";

export const notificationProvider: NotificationProvider = {
  open: ({
    key,
    message,
    type,
    undoableTimeout,
    cancelMutation,
    description,
  }) => {
    if (type === "progress") {
      if (toast.isActive(key as React.ReactText)) {
        toast.update(key as React.ReactText, {
          progress: undoableTimeout && (undoableTimeout / 10) * 2,
          render: (
            <UndoableNotification
              message={message}
              description={description}
              cancelMutation={cancelMutation}
            />
          ),
          type: "default",
        });
      } else {
        toast(
          <UndoableNotification
            message={message}
            description={description}
            cancelMutation={cancelMutation}
          />,
          {
            toastId: key,
            updateId: key,
            closeOnClick: false,
            closeButton: false,
            autoClose: false,
            progress: undoableTimeout && (undoableTimeout / 10) * 2,
          }
        );
      }
    } else {
      if (toast.isActive(key as React.ReactText)) {
        toast.update(key as React.ReactText, {
          render: message,
          closeButton: true,
          autoClose: 5000,
          type,
        });
      } else {
        toast(<ToastMessage message={message} description={description} />, {
          toastId: key,
          type,
        });
      }
    }
  },
  close: (key) => toast.dismiss(key),
};

const ToastMessage = ({
  message,
  description,
}: {
  message: string;
  description?: string;
}) => {
  return (
    <>
      <div>{message}</div>
      <div className={"text-sm"}>{description}</div>
    </>
  );
};

type UndoableNotification = {
  message: string;
  description?: ReactNode | string;
  cancelMutation?: () => void;
  closeToast?: () => void;
};

export const UndoableNotification: React.FC<UndoableNotification> = ({
  closeToast,
  cancelMutation,
  message,
  description,
}) => {
  return (
    <div>
      <p>{message}</p>
      <div>{description}</div>
      <button
        onClick={() => {
          cancelMutation?.();
          closeToast?.();
        }}
      >
        Undo
      </button>
    </div>
  );
};
