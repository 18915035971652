import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  cn,
  Divider,
  Image,
} from "@nextui-org/react";
import { useApiUrl } from "@refinedev/core";
import React from "react";
import { useActiveAssociation } from "../../../context/active-association.context";
import { useAssociationUserShow } from "../../../context/user-show.context";
import { useFileEntityUrl } from "../../../hooks";
import { getCountryFlagUrl } from "../../../lib/flag-map";
import { BackgroundNumbers } from "./background-numbers";
import { OfficialCgpCardHeader } from "./official-cgp-card-header";
export const Card1 = ({ isAdminView }: { isAdminView?: boolean }) => {
  return (
    <Card
      className={cn(
        "bg-primary w-full h-full rounded-none",
        isAdminView ? "h-full" : "h-screen"
      )}
    >
      <CardHeader className={"p-0 h-28 rounded-none"}>
        <OfficialCgpCardHeader />
        <HeaderImage />
      </CardHeader>
      <CardBody className={"bg-primary overflow-hidden p-0 mt-6"}>
        <BackgroundNumbers />
        <div className={"grid grid-cols-1 mt-28 mb-12"}>
          <UserInfoName />
          <CountryFlag />
        </div>
      </CardBody>
      <CardFooter className={"bg-none h-20 p-0 bg-secondary rounded-none"}>
        <FooterContents />
      </CardFooter>
    </Card>
  );
};
const HeaderImage: React.FC = () => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div className={"flex justify-center z-10 w-full relative"}>
      <Divider
        className={"bg-secondary absolute h-0.5 top-20 mt-1.5"}
      ></Divider>
      <Avatar
        className={
          "w-[165px] h-[165px] text-large border-secondary drop-shadow-xl shadow-sm shadow-black border-2 absolute"
        }
        src={useFileEntityUrl(associationUser?.user?.photoId)}
        color={"secondary"}
        name={`${associationUser?.user?.firstName} ${associationUser?.user?.lastName}`}
      ></Avatar>
    </div>
  );
};

const CountryFlag: React.FC = () => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div className={"flex flex-row justify-center items-center w-full z-10"}>
      <span className={"relative tinyTopDivider"}>
        <Image
          className={"rounded-full"}
          src={getCountryFlagUrl(associationUser?.association?.country)}
          width={70}
          height={70}
        />
      </span>
    </div>
  );
};

const UserInfoName: React.FC = ({}) => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div className={"flex justify-center my-3 z-10"}>
      <div className={"flex flex-col justify-center items-center"}>
        <div
          className={
            "text-5xl font-light text-light font-serif text-center leading-[1.1] tracking-wide"
          }
        >
          {associationUser?.user?.firstName} <br />
          {associationUser?.user?.lastName}
        </div>
        <div
          className={
            "text-light/90 text-xs mt-2 mb-8 uppercase tracking-[0.25rem]"
          }
        >
          {associationUser?.associationRole?.name}
        </div>
      </div>
    </div>
  );
};

const FooterContents = () => {
  const { associationUser } = useAssociationUserShow();
  const apiUrl = useApiUrl();
  return (
    <>
      <div
        className={
          "flex flex-row justify-start items-center w-2/3 h-full bg-white border-t-secondary border-t-2 l"
        }
      >
        {associationUser?.association?.logoId && (
          <Image
            className={"p-2 rounded-full bg-light backdrop-blur-2xl ml-2"}
            src={`${apiUrl}/files/${associationUser.association?.logoId}`}
            width={60}
            height={60}
          />
        )}
        <div
          className={"ml-2 capitalize font-semibold text-dark text-sm w-2/3"}
        >
          Member of {associationUser?.association?.name}
        </div>
      </div>
      <div
        className={
          "ml-2 w-1/3 bg-secondary h-full flex justify-center items-center"
        }
      >
        <span className={"text-2xl text-light"}>2024/25</span>
      </div>
    </>
  );
};
