import React from "react";

export const BackgroundNumbers: React.FC<{
  textColor?: string;
}> = ({ textColor = "text-primary-shade" }) => {
  return (
    <>
      <div
        className={`z-0 text-center w-full absolute top-0 h-full ${textColor} select-none`}
      >
        <span
          className={
            "font-poppins font-thin text-[20rem] absolute left-10  leading-[15rem] inline-block"
          }
        >
          24
        </span>
        <span
          className={
            "font-serif font-thin text-[20rem] absolute bottom-0 right-4 leading-[10rem] inline-block"
          }
        >
          25
        </span>
      </div>
      {/*<div>*/}
      {/*  <span className={"font-serif ml-20 font-thin -mt-20"}>25</span>*/}
      {/*</div>*/}
    </>
  );
};
