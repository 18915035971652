// This TypeScript file contains a React functional component for rendering a list of associations with various functionalities like sorting, filtering, and selection. It also includes components for top content and multiple selection dropdown. The component checks for user roles to determine access rights.

import React, { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  cn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from "@nextui-org/react";
import {
  CrudSort,
  IResourceComponentsProps,
  useApiUrl,
  useCreate,
  useDelete,
  useGetIdentity,
  useNavigation,
  useResource,
  useTable,
  useTableReturnType,
} from "@refinedev/core";
import {
  IconChevronDown,
  IconDotsVertical,
  IconMailFast,
  IconPlus,
} from "@tabler/icons-react";
import { UserUpsertForm } from "../../components/forms/upsert-user-form/user-upsert-form";
import { TableSearchInput } from "../../components/table/search-input";
import { TableBottomContent } from "../../components/table/table-bottom-content";
import { TableTitle } from "../../components/table/table-title";
import { useActiveAssociation } from "../../context/active-association.context";
import { useDrawer } from "../../context/drawer.context";
import { getCountryFlagUrl } from "../../lib/flag-map";
import { getDotNotationPropertyValue } from "../../lib/utils";
import { ResourceIdentifier } from "../../resources";
import { Association, User } from "../../sdk";
import { AssociationEdit } from "./association-upsert";

type Selection = string[] | undefined;

/**
 * Represents the configuration of table columns for Association component.
 *
 * @typedef {Object} ColumnConfig
 * @property {string} header - The header label for the column.
 * @property {string} key - The key used to access the column data in the data source.
 * @property {string} valueKey - The key used to access a specific value within the column data (optional).
 * @property {boolean} sortable - Indicates whether the column is sortable (true) or not (false).
 * @property {string} sortableKey - The key used to access the sorting data in the data source (optional).
 */
// TODO: Add association logo to the table
const columns = [
  {
    header: "Association",
    key: "association",
    sortable: false,
    sortableKey: "",
  },
  { header: "Actions", key: "actions", sortable: false },
];

/**
 * React functional component for rendering an association list.
 *
 * @component
 * @example
 * <AssociationList />
 *
 * @returns {ReactNode} The rendered component.
 */
export const AssociationList: React.FC<IResourceComponentsProps> = () => {
  const routeParams = useParams();
  const activeAssociation = useActiveAssociation();
  const navigate = useNavigate();
  const { data } = useGetIdentity<User>();
  const { data: association } = useGetIdentity<Association>();
  const {
    tableQueryResult,
    pageCount,
    current,
    pageSize,
    filters,
    setCurrent,
    setPageSize,
    setSorters,
    setFilters,
  } = useTable<Association>({
    resource: ResourceIdentifier.Association,
    syncWithLocation: true,
  });
  const { edit, show, create, showUrl } = useNavigation();
  const { mutate: deleteProduct } = useDelete();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [deleteItem, setDeleteItem] = useState<null | Association>(null);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "id",
    direction: "ascending",
  });
  const [selected, setSelected] = useState<Selection>();
  const { toggleDrawer } = useDrawer();
  const apiUrl = useApiUrl();

  const renderCell = useCallback(
    (columnKey: string, item: any) => {
      const column = columns.find((column) => column.key === columnKey);

      if (columnKey === "association") {
        // console.log("Association field", { item });
        const association = item;
        return (
          <TableCell>
            <div className={"flex flex-row align-middle items-center"}>
              <Image
                className={"p-2 rounded-full bg-light backdrop-blur-2xl mr-1"}
                src={getCountryFlagUrl(association?.country)}
                width={50}
                height={50}
              />
              {association?.name}
            </div>
          </TableCell>
        );
      }
      if (columnKey === "actions") {
        return (
          <TableCell>
            <div className="flex items-center justify-center">
              <Dropdown>
                <DropdownTrigger>
                  <Button variant="ghost" isIconOnly={true}>
                    <IconDotsVertical className={"h-4 w-4"} />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  variant="faded"
                  aria-label="Dropdown menu with icons"
                >
                  {/* Dropdown items */}
                </DropdownMenu>
              </Dropdown>
            </div>
          </TableCell>
        );
      }
      return (
        <TableCell>{getDotNotationPropertyValue(item, columnKey)}</TableCell>
      );
    },
    [tableQueryResult?.data?.data, association]
  );

  if (data?.roles?.some((role) => role.name === "superadmin")) {
    return (
      <>
        <Table
          color={"primary"}
          isStriped={false}
          aria-label="Associations table"
          sortDescriptor={sortDescriptor}
          onSelectionChange={(e) => {
            if (e === "all") {
              setSelected(tableQueryResult?.data?.data.map((el) => el.id));
            } else {
              setSelected([...e].map((el) => el) as any);
            }
          }}
          onSortChange={(e) => {
            const sorter: CrudSort = {
              order: e.direction === "ascending" ? "asc" : "desc",
              field: e.column as string,
            };
            const field = columns.find(
              (column) => column.key === e.column
            )?.sortableKey;
            if (field) {
              sorter.field = field;
            }
            setSorters([sorter]);
            setSortDescriptor(e);
          }}
          onRowAction={(item) => {
            show(ResourceIdentifier.Association, `${item}`, undefined, {
              associationId: item,
            });
          }}
          topContent={
            <TopContent
              setCurrent={setCurrent}
              setFilters={setFilters}
              filters={filters}
              selected={tableQueryResult?.data?.data.filter((el) =>
                selected?.includes(el.id)
              )}
            />
          }
          bottomContent={
            <TableBottomContent
              current={current}
              pageCount={pageCount}
              setCurrent={setCurrent}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          }
        >
          <TableHeader columns={columns}>
            {(column) => {
              if (column.key === "actions") {
                return (
                  <TableColumn
                    accessKey={column.sortableKey}
                    allowsSorting={column.sortable}
                    key={column.key}
                    className="text-center px-4 w-20"
                  >
                    {column.header}
                  </TableColumn>
                );
              }
              return (
                <TableColumn allowsSorting={column.sortable}>
                  {column.header}
                </TableColumn>
              );
            }}
          </TableHeader>
          {tableQueryResult?.data?.data.length ? (
            <TableBody items={tableQueryResult?.data?.data}>
              {(item) => {
                return (
                  <TableRow className={"cursor-pointer"} key={item.id}>
                    {(columnKey) => {
                      return renderCell(columnKey as string, item);
                    }}
                  </TableRow>
                );
              }}
            </TableBody>
          ) : (
            <TableBody emptyContent={"No rows to display."}>{[]}</TableBody>
          )}
        </Table>
      </>
    );
  } else {
    return <div>You don't have access</div>;
  }
};

export type TableTopContentProps = {
  setCurrent: useTableReturnType["setCurrent"];
  setFilters: useTableReturnType["setFilters"];
  filters: useTableReturnType["filters"];
  selected: Association[] | undefined;
};

export const TopContent: FC<TableTopContentProps> = ({
  setCurrent,
  setFilters,
  filters,
  selected,
}) => {
  const { toggleDrawer } = useDrawer();
  const { create, createUrl } = useNavigation();
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between gap-3">
        <TableTitle title={"Associations"}></TableTitle>
        <Button
          variant="bordered"
          startContent={<IconPlus className="h-4 w-4" />}
          onPress={() => {
            toggleDrawer({
              opened: true,
              content: <AssociationEdit isCreate={true} />,
              headerText: `Add member`,
            });
          }}
        >
          {"Create association"}
        </Button>
      </div>
      <div className="flex justify-between items-end">
        <TableSearchInput
          setFilters={setFilters}
          filters={filters}
          setCurrent={setCurrent}
        />
      </div>
    </div>
  );
};

const MultipleSelectionDropdown = ({
  selected,
}: {
  selected: Association[] | undefined;
}) => {
  const [isOpen, onOpenChange] = useState(false);
  const { mutateAsync: sendInvite } = useCreate();
  const activeAssociation = useActiveAssociation();
  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button
            variant="ghost"
            isDisabled={!selected || selected?.length === 0}
          >
            {(selected?.length || 0) > 0
              ? selected?.length + " selected"
              : "No selection"}
            <IconChevronDown className={"w-4 h-4"} />
          </Button>
        </DropdownTrigger>
        <DropdownMenu variant="faded" aria-label="Dropdown menu with icons">
          <DropdownItem
            onPress={() => {
              onOpenChange(true);
            }}
            key="sendMultipleInvites"
            startContent={<IconMailFast className={"h-4 w-4"} />}
          >
            Send invite
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
