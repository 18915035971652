import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@nextui-org/react";
import { useCreate, useUpdate } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import React from "react";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { FormInput } from "../../components/ui/form/form-input";
import { useActiveAssociation } from "../../context/active-association.context";
import { useDrawer } from "../../context/drawer.context";
import { ResourceIdentifier } from "../../resources";
import { Certificate } from "../../sdk";

export const UpsertCertificateForm: React.FC<{
  certificate?: Certificate;
}> = ({ certificate }) => {
  const { closeDrawer } = useDrawer();
  const association = useActiveAssociation();

  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const form = useForm({
    defaultValues: {
      name: "",
    },
    values: {
      name: certificate?.name || "",
    },
    resolver: yupResolver(schema),
    refineCoreProps: {
      resource: ResourceIdentifier.Certificate,
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const [loading, setLoading] = React.useState(false);

  const { mutate: updateAssociationCertificate } = useUpdate();
  const { mutate: createAssociationCertificate } = useCreate();

  const onSuccess = () => {
    closeDrawer();
  };

  const onSettled = () => {
    setLoading(false);
  };
  const upsertAssociation = async (data: yup.InferType<typeof schema>) => {
    setLoading(true);
    const resource = ResourceIdentifier.Certificate;
    const upsertArgs = {
      resource,
      values: {
        ...data,
        association: {
          id: association,
        }?.id,
      },
      successNotification: {
        message: "Association certificate created successfully",
        type: "success" as const,
      },
      errorNotification: (error: any) => {
        console.log("ERROR ", error);
        return {
          message: "Error updating association certificate",
          description: error?.message,
          type: "error" as const,
          position: "top-center",
        };
      },
    };

    if (!certificate?.id) {
      createAssociationCertificate(
        {
          ...upsertArgs,
        },
        {
          onSuccess: onSuccess,
          onSettled: onSettled,
        }
      );
    } else {
      await updateAssociationCertificate(
        {
          ...upsertArgs,
          id: certificate?.id!,
          successNotification: {
            message: "Association certificate updated successfully",
            type: "success" as const,
          },
        },
        {
          onSuccess: onSuccess,
          onSettled: onSettled,
        }
      );
    }
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(upsertAssociation)}>
        <div className={"grid gap-4 mb-6"}>
          <FormInput
            name={"name"}
            label={"Certificate name"}
            variant={"bordered"}
            control={control}
          />
        </div>
        <Button
          fullWidth
          size={"lg"}
          color={"secondary"}
          type={"submit"}
          isLoading={loading}
          isDisabled={loading}
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
};
