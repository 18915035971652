import { Button, cn } from "@nextui-org/react";
import { IconTrash } from "@tabler/icons-react";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Button as DropzoneButton,
  DropZone,
  FileDropItem,
  FileTrigger,
  Text,
} from "react-aria-components";
import { CropperModal } from "./cropper-modal";

export const ImageDropzone: FC<{
  files: File[] | null;
  setFiles: Dispatch<SetStateAction<null | File[]>>;
  label?: string;
  existingImageUrl?: string;
  onClear?: () => void;
  onImageChanged: () => void;
}> = ({
  files,
  setFiles,
  label,
  existingImageUrl,
  onClear,
  onImageChanged,
}) => {
  useEffect(() => {
    setBackgroundImage(existingImageUrl);
  }, [existingImageUrl]);

  function convertDataUrlToBlob(dataUrl: string): Blob | string {
    console.log({ dataUrl });
    const arr = dataUrl.split(",");
    const mime = arr[0]?.match(/:(.*?);/)?.[1];
    if (!arr[1]) {
      return dataUrl as string;
    }
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  const [backgroundImage, setBackgroundImage] = useState<string | undefined>(
    existingImageUrl
  );
  const [image, setImage] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log({ backgroundImage });
    setFiles(
      backgroundImage
        ? [
            new File(
              [convertDataUrlToBlob(backgroundImage)],
              `profile-image.jpg`
            ),
          ]
        : null
    );
  }, [backgroundImage]);

  useEffect(() => {
    if (image) {
      setIsOpen(true);
    }
  }, [image]);

  return (
    <>
      <CropperModal
        isOpen={isOpen}
        image={image!}
        onOpenChange={setIsOpen}
        onSetCroppedImage={(value) => {
          setBackgroundImage(value);
          onImageChanged();
        }}
      />
      <DropZone
        className={cn(
          "border-2 rounded-2xl p-4 flex flex-col items-center justify-center relative hover:bg-slate-100 h-48"
        )}
        onDrop={async (e) => {
          const files = e.items.filter(
            (file) =>
              file.kind === "file" &&
              (file.type.startsWith("image/") || file.type.startsWith("img/"))
          ) as FileDropItem[];
          if (files.length > 0) {
            // const fileText = await files[0].getText();
            // console.log({ fileText });
            const file = files[0];
            const reader = new FileReader();

            reader.addEventListener(
              "load",
              () => {
                setImage(reader.result as string);
              },
              false
            );

            if (file) {
              reader.readAsDataURL(await file.getFile());
            }
            // setImage(fileText);
            // files[0].getText().then((file) => {
            //   setImage(file);
            // });
            // files[0].getFile().then((file) => {
            //   setFiles([file]);
            // });
          }
        }}
      >
        <FileTrigger
          acceptedFileTypes={["image/*", "img/*"]}
          onSelect={async (e) => {
            if (!e) return;
            const files = Array.from(e);
            const images = files.filter(
              (file) =>
                file.type.startsWith("image/") || file.type.startsWith("img/")
            );

            // if (images.length > 0) {
            //   const imageText = await images[0].text();
            //   console.log("File triggert image text", imageText);
            //   setImage(imageText);
            // }
            const file = images[0];
            const reader = new FileReader();

            reader.addEventListener(
              "load",
              () => {
                console.log("Seetting image", reader.result);
                setImage(reader.result as string);
              },
              false
            );

            if (file) {
              reader.readAsDataURL(file);
            }
          }}
        >
          {backgroundImage && (
            <Button
              variant={"light"}
              size={"sm"}
              color={"danger"}
              className={"absolute top-2 right-1 z-10"}
              onPress={() => {
                setFiles(null);
                setBackgroundImage(undefined);
                onClear?.();
              }}
            >
              <IconTrash />
            </Button>
          )}
          <Button
            style={{
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
              objectFit: "cover",
              backgroundImage: backgroundImage
                ? `url(${
                    typeof backgroundImage === "string"
                      ? backgroundImage
                      : URL.createObjectURL(backgroundImage)
                  })`
                : "none",
            }}
            data-rac
            as={DropzoneButton}
            className={
              "w-full h-full absolute bg-no-repeat bg-center bg- bg-light"
            }
          ></Button>
          {!backgroundImage && (
            <Text slot="label" className={"block z-10"}>
              {label || "Drop files here"}
            </Text>
          )}
        </FileTrigger>
      </DropZone>
    </>
  );
};
