import {
  Button,
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from "@nextui-org/react";
import {
  CrudSort,
  IResourceComponentsProps,
  useDelete,
  useInvalidate,
  useNavigation,
  useTable,
} from "@refinedev/core";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TableTopContent } from "../../../components/table/table-top-content";
import { ConfirmationModal } from "../../../components/ui/confirmation-modal";
import { useActiveAssociation } from "../../../context/active-association.context";
import { useDrawer } from "../../../context/drawer.context";
import { ResourceIdentifier } from "../../../resources";
import { AssociationGolfClass } from "../../../sdk";
import { AssociationRoles } from "../association-role/association-roles";
import { UpsertAssociationGolfClassForm } from "./upsert-association-golf-class-form";

const columns = [
  { header: "Id", key: "id", sortable: false },
  { header: "Name", key: "name", sortable: false },
  { header: "Actions", key: "actions", sortable: false },
];

export const AssociationGolfClasses: React.FC<
  IResourceComponentsProps
> = () => {
  const routeParams = useParams();

  const { toggleDrawer } = useDrawer();

  const activeAssociation = useActiveAssociation();
  const { tableQueryResult, setSorters, sorters } =
    useTable<AssociationGolfClass>({
      resource: ResourceIdentifier.AssociationGolfClass,
      syncWithLocation: false,
    });

  const invalidate = useInvalidate();

  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "id",
    direction: "ascending",
  });

  const { edit } = useNavigation();
  const { mutate: deleteProduct } = useDelete();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [deleteItemId, setDeleteItemId] = useState<null | number>(null);

  const renderCell = useCallback(
    (columnKey: string, item: any) => {
      if (columnKey === "actions") {
        return (
          <TableCell>
            <div className="flex gap-1 items-center justify-end">
              <Button
                isIconOnly
                size="sm"
                variant="light"
                color="default"
                aria-label="Edit"
                className="rounded-full"
                onPress={() => {
                  toggleDrawer({
                    opened: true,
                    content: (
                      <UpsertAssociationGolfClassForm
                        associationGolfClass={item}
                      />
                    ),
                    headerText: "Edit golf class",
                  });
                }}
              >
                <IconPencil className="h-4 w-4" />
              </Button>
              <Button
                isIconOnly
                variant="light"
                color="danger"
                size="md"
                aria-label="Delete"
                className="rounded-full"
                onPress={() => {
                  setDeleteItemId(item.id);
                  onOpen();
                }}
              >
                <IconTrash className="h-4 w-4" />
              </Button>
            </div>
          </TableCell>
        );
      }

      return <TableCell>{item[columnKey]}</TableCell>;
    },
    [tableQueryResult?.data?.data]
  );

  return (
    <>
      <Table
        isStriped
        aria-label="Golf class table"
        onSortChange={(e) => {
          const sorter: CrudSort = {
            order: e.direction === "ascending" ? "asc" : "desc",
            field: e.column as string,
          };

          setSorters([sorter]);
          setSortDescriptor(e);
        }}
        sortDescriptor={sortDescriptor}
        topContent={
          <>
            <span className={"text-xl font-bold"}>Golf classes</span>
            <TableTopContent
              search={false}
              resource={`association/${routeParams.associationId}/golf-class`}
              buttons={
                <>
                  <Button
                    key={"new-golf-class"}
                    variant={"bordered"}
                    onPress={() => {
                      toggleDrawer({
                        opened: true,
                        content: <UpsertAssociationGolfClassForm />,
                        headerText: "Add golf class",
                      });
                    }}
                  >
                    New golf class
                  </Button>
                </>
              }
            />
          </>
        }
        // bottomContent={<TableBottomContent />}
      >
        <TableHeader columns={columns}>
          {(column) => {
            if (column.key === "actions") {
              return (
                <TableColumn
                  allowsSorting={column.sortable}
                  key={column.key}
                  className="text-end pr-8"
                >
                  {column.header}
                </TableColumn>
              );
            }

            return (
              <TableColumn allowsSorting={column.sortable} key={column.key}>
                {column.header}
              </TableColumn>
            );
          }}
        </TableHeader>
        {tableQueryResult?.data?.data.length ? (
          <TableBody
            items={tableQueryResult?.data?.data.sort((a, b) => a.id - b.id)}
          >
            {(item) => {
              return (
                <TableRow key={item.id}>
                  {(columnKey) => {
                    return renderCell(columnKey as string, item);
                  }}
                </TableRow>
              );
            }}
          </TableBody>
        ) : (
          <TableBody emptyContent={"No rows to display."}>{[]}</TableBody>
        )}
      </Table>
      {isOpen ? (
        <ConfirmationModal
          isOpen={isOpen}
          onConfirm={() =>
            deleteProduct(
              {
                resource: ResourceIdentifier.AssociationGolfClass,
                id: deleteItemId as number,
                successNotification: {
                  message: "Golf class deleted successfully",
                  type: "success",
                },
                errorNotification: (error) => {
                  return {
                    message: "Error deleting golf class",
                    description: error?.message,
                    type: "error",
                    position: "top-center",
                  };
                },
              },
              {
                onSuccess: () => {
                  invalidate({
                    resource: ResourceIdentifier.Association,
                    id: activeAssociation?.id,
                    invalidates: ["detail"],
                  });
                },
              }
            )
          }
          onOpenChange={onOpenChange}
          warningMessage={`You are about to delete product with id ${deleteItemId} from the database. This action is irreversible.`}
        />
      ) : null}
    </>
  );
};
