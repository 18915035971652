/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssociationRole } from '../models/AssociationRole';
import type { CreateAssociationRoleDto } from '../models/CreateAssociationRoleDto';
import type { UpdateAssociationRoleDto } from '../models/UpdateAssociationRoleDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AssociationRoleService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns AssociationRole
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody: CreateAssociationRoleDto,
  }): CancelablePromise<AssociationRole> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/association/{associationId}/role',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any[]
   * @throws ApiError
   */
  public find(): CancelablePromise<any[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/association/{associationId}/role',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public updateById({
    id,
    requestBody,
    relations,
  }: {
    id: number,
    requestBody: UpdateAssociationRoleDto,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/association/{associationId}/role/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public deleteById({
    id,
    relations,
  }: {
    id: number,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/association/{associationId}/role/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
    });
  }
}
