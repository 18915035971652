import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  cn,
  Divider,
  Image,
  tv,
} from "@nextui-org/react";
import React from "react";
import { useAssociationUserShow } from "../../../context/user-show.context";
import { BackgroundNumbers } from "./background-numbers";
import { CpgSponsors } from "./cpg-sponsors";
import { OfficialCgpCardHeader } from "./official-cgp-card-header";
import { UserQrCode } from "./user-qr-code";

export const Card3 = ({ isAdminView }: { isAdminView?: boolean }) => {
  return (
    <Card
      className={cn(
        "bg-primary w-full  rounded-none ",
        isAdminView ? "h-full" : "h-screen"
      )}
    >
      <CardHeader className={"p-0 h-32 rounded-none"}>
        <OfficialCgpCardHeader />
        <HeaderImage />
      </CardHeader>
      <CardBody
        className={"bg-secondary overflow-hidden p-0 min-h-[367px] mt-4"}
      >
        <BackgroundNumbers textColor={"text-secondary-shade"} />
        <div className={"grid grid-cols-1 mt-10 mb-12"}>
          <UserInfoName />
        </div>
      </CardBody>
      <CardFooter className={"bg-none h-28 p-0 bg-secondary rounded-none"}>
        <FooterContents />
      </CardFooter>
    </Card>
  );
};

const HeaderImage: React.FC = () => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div className={"flex justify-center z-10 w-full relative"}>
      <Divider className={"bg-secondary absolute h-0.5 top-20"}></Divider>
      <div
        className={
          "w-32 h-40 text-large border-secondary absolute rounded-full flex flex-col justify-center"
        }
      >
        <Image
          src={`${
            import.meta.env.VITE_PUBLIC_BACKEND_API_BASE_URL
          }/cpg-logo.png`}
          height={180}
          width={180}
        />
      </div>
    </div>
  );
};

const UserInfoName: React.FC = ({}) => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div className={"flex justify-center my-3 z-10"}>
      <div className={"flex flex-col justify-center items-center"}>
        <div className={"relative mt-5"}>
          <div
            className={
              "flex flex-col text-center text-light text-md font-semibold uppercase tracking-widest mt-0 mb-8 gap-y-8 gap-x-2"
            }
          >
            <div>
              Golf Professional Level
              <br />
              <span className={"font-normal"}>
                {associationUser?.golfClass?.name}
              </span>
            </div>
            <div>
              Golf Coach level <br />
              <span className={"font-normal"}>
                {associationUser?.coachClass?.name}
              </span>
            </div>
            <div>
              Certificates <br />
              <span className={"font-normal flex flex-col"}>
                {associationUser?.certificates?.map((certificate) => (
                  <span key={certificate.id}>{certificate.name}</span>
                ))}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FooterContents = React.memo(() => {
  return <CpgSponsors></CpgSponsors>;
});
