import { ResourceProps } from "@refinedev/core";
import {
  IconAbc,
  IconBox,
  IconCertificate,
  IconMilitaryRank,
  IconSchool,
  IconShape,
  IconSmartHome,
  IconUserCog,
  IconUserHexagon,
  IconUsers,
  IconUsersGroup,
} from "@tabler/icons-react";
import React from "react";

export const ResourceIdentifier = {
  Association: "Association",
  AssociationRole: "AssociationRole",
  AssociationUser: "AssociationUser",
  AssociationGolfClass: "AssociationGolfClass",
  Certificate: "Certificate",
  CoachClass: "CoachClass",
  GolfProfessionalLevel: "GolfProfessionalLevel",
  User: "User",
  Membership: "Membership",
  UserMembership: "UserMembership",
  AssociationInvite: "Invite",
  AssociationAdminInvite: "AdminInvite",
  MembershipCardLink: "MembershipCardLink",
  ChangeRequest: "ChangeRequest",
};

export const apiResources: ResourceProps[] = [
  {
    identifier: ResourceIdentifier.Association,
    name: "association",
    edit: "/association/:associationId/edit",
    show: "/association/:associationId",
    list: "/",
    meta: {
      label: "Home",
      icon: <IconSmartHome />,
    },
  },
  {
    identifier: ResourceIdentifier.AssociationUser,
    name: "association/:associationId/user",
    list: "/association/:associationId/user",
    create: "/association/:associationId/user/create",
    edit: "/association/:associationId/user/:id/edit",
    show: "/association/:associationId/user/:id",
    meta: {
      label: "Members",
      canDelete: true,
      icon: <IconUsersGroup />,
    },
  },
  {
    identifier: ResourceIdentifier.ChangeRequest,
    name: "association/:associationId/change-request",
    list: "association/:associationId/change-request",
    show: "association/:associationId/change-request/:id",
    meta: {
      label: "Change requests",
      icon: <IconShape />,
    },
  },
  {
    identifier: ResourceIdentifier.AssociationRole,
    name: "association/:associationId/role",
    list: "/association/:associationId/role",
    meta: {
      icon: <IconUserCog />,
      label: "Roles",
    },
  },
  {
    identifier: ResourceIdentifier.AssociationGolfClass,
    name: "association/:associationId/golf-class",
    list: "/association/:associationId/golf-class",
    meta: {
      label: "Golf Class",
      icon: <IconMilitaryRank />,
    },
  },
  {
    name: "Other",
    identifier: "Other",
    meta: {
      label: "CPG",
      icon: <IconBox />,
      roles: ["superadmin"],
    },
  },

  {
    identifier: ResourceIdentifier.Certificate,
    name: "certificate",
    // list: "/certificate",
    meta: {
      parent: "Other",
      label: "Certificates",
      icon: <IconCertificate />,
      // hide: true,
    },
  },
  {
    identifier: ResourceIdentifier.CoachClass,
    name: "coach-class",
    list: "/coach-class",
    meta: {
      parent: "Other",
      icon: <IconSchool />,
      label: "Coach level",
    },
  },
  {
    identifier: ResourceIdentifier.GolfProfessionalLevel,
    name: "golf-professional-level",
    list: "/golf-professional-level",
    meta: {
      parent: "Other",
      icon: <IconMilitaryRank />,
      label: "Golf Professional Levels",
    },
  },
  {
    identifier: ResourceIdentifier.User,
    name: "users",
  },
  {
    identifier: ResourceIdentifier.Membership,
    name: "association/:associationId/membership",
  },
  {
    identifier: ResourceIdentifier.UserMembership,
    name: "association/:associationId/membership/:associationUserId",
  },
  {
    identifier: ResourceIdentifier.AssociationInvite,
    name: "association/:associationId/invite",
  },
  {
    identifier: ResourceIdentifier.AssociationAdminInvite,
    name: "association/:associationId/invite/admin",
  },
  // TODO: This path needs to be moved to something like: /association/:associationId/mail/send-membership-link
  {
    identifier: ResourceIdentifier.MembershipCardLink,
    name: "association/:associationId/invite/send-membership-card-link",
  },
];
