/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssociationGolfClass } from '../models/AssociationGolfClass';
import type { CreateAssociationGolfClassDto } from '../models/CreateAssociationGolfClassDto';
import type { UpdateAssociationGolfClassDto } from '../models/UpdateAssociationGolfClassDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AssociationGolfClassService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns AssociationGolfClass
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody: CreateAssociationGolfClassDto,
  }): CancelablePromise<AssociationGolfClass> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/association/{associationId}/golf-class',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any[]
   * @throws ApiError
   */
  public find(): CancelablePromise<any[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/association/{associationId}/golf-class',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public updateById({
    id,
    requestBody,
    relations,
  }: {
    id: number,
    requestBody: UpdateAssociationGolfClassDto,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/association/{associationId}/golf-class/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public deleteById({
    id,
    relations,
  }: {
    id: number,
    relations?: Array<string>,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/association/{associationId}/golf-class/{id}',
      path: {
        'id': id,
        'relations': relations,
      },
    });
  }
}
