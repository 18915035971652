import { Button, Input } from "@nextui-org/react";
import { getDefaultFilter, useNavigation, useTable } from "@refinedev/core";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import { TableSearchInput } from "./search-input";

export type TableTopContentProps = {
  actionsChildren?: React.ReactNode;
  create?: (resource: string) => void;
  createButtonLabel?: string;
  buttons?: React.ReactNode;
  resource?: string;
  search?: boolean;
};
export const TableTopContent: React.FC<TableTopContentProps> = ({
  createButtonLabel,
  resource,
  search,
  buttons,
}) => {
  const { setCurrent, setPageSize, setFilters, filters } = useTable({
    resource: resource,
    queryOptions: {
      enabled: !!resource,
    },
  });
  const { create, createUrl } = useNavigation();
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-end gap-3">
        {!buttons && (
          <Button
            color="primary"
            variant="bordered"
            startContent={<IconPlus className="h-4 w-4" />}
            onPress={() => {
              create(resource!);
            }}
          >
            {createButtonLabel || "Create"}
          </Button>
        )}
        {!!buttons && buttons}
      </div>
      {search && (
        <div className="flex justify-end">
          <TableSearchInput
            filters={filters}
            setFilters={setFilters}
            setCurrent={setCurrent}
          />
        </div>
      )}
    </div>
  );
};
