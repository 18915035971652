import { axiosInstance } from "../rest-data-provider/utils";
import { PgaSdk, User } from "../sdk";
import { REFRESH_TOKEN_KEY, TOKEN_EXPIRES_KEY, TOKEN_KEY } from "./constants";

export const ApiSdk = new PgaSdk({
  BASE: import.meta.env.VITE_PUBLIC_BACKEND_API_BASE_URL,
  TOKEN: async (apiRequestOptions): Promise<string> => {
    const tokenExpiration = localStorage.getItem(TOKEN_EXPIRES_KEY);
    const isExpired = !tokenExpiration || Number(tokenExpiration) < Date.now();

    if (tokenExpiration && isExpired) {
      await refreshAuthToken();
    }

    const token = localStorage.getItem(TOKEN_KEY);

    console.log("Setting server session in API SDK", token);
    return token || "";
  },
  HEADERS: async () => {
    if (!import.meta.env.VITE_PUBLIC_SELF_DOMAIN) {
      console.warn("VITE_PUBLIC_SELF_DOMAIN" + " not found in environment");
    }

    return {
      "x-application": "CPG-PWA",
      "x-application-domain": import.meta.env.VITE_PUBLIC_SELF_DOMAIN!,
    };
  },
});

const refreshAuthToken = async () => {
  const tokenRefreshResponse = await axiosInstance.post(
    `${import.meta.env.VITE_PUBLIC_BACKEND_API_URL}/auth/refresh`,
    {},
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(REFRESH_TOKEN_KEY),
      },
    }
  );
  localStorage.setItem(TOKEN_KEY, tokenRefreshResponse.data.token);
  localStorage.setItem(
    TOKEN_EXPIRES_KEY,
    tokenRefreshResponse.data.tokenExpires
  );
  localStorage.setItem(
    REFRESH_TOKEN_KEY,
    tokenRefreshResponse.data.refreshToken
  );
};

type Headers = Record<string, any>;
