import {
  cn,
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  User,
} from "@nextui-org/react";
import {
  CrudSort,
  useApiUrl,
  useGetIdentity,
  useNavigation,
  useTable,
  useTableReturnType,
} from "@refinedev/core";
import React, { FC, ReactElement, useCallback, useState } from "react";
import { TableSearchInput } from "../../components/table/search-input";
import { TableBottomContent } from "../../components/table/table-bottom-content";
import { TableTitle } from "../../components/table/table-title";
import { useActiveAssociation } from "../../context/active-association.context";
import { useDrawer } from "../../context/drawer.context";
import { getDotNotationPropertyValue } from "../../lib/utils";
import { ResourceIdentifier } from "../../resources";
import { ChangeRequest, User as Identity } from "../../sdk";
import { StatusBadge } from "./change-request-status-badge";

export const ChangeRequestList = () => {
  const activeAssociation = useActiveAssociation();

  const { data: user } = useGetIdentity<Identity>();

  const columns = [
    {
      header: "User",
      key: "associationUser",
      sortable: false,
    },
    {
      header: "Change type",
      key: "type",
    },
    {
      header: "Status",
      key: "status",
    },
  ];

  const {
    tableQueryResult,
    pageCount,
    current,
    pageSize,
    filters,
    setCurrent,
    setPageSize,
    setSorters,
    setFilters,
  } = useTable<ChangeRequest>({
    resource: ResourceIdentifier.ChangeRequest,
    meta: {
      associationId: activeAssociation?.id,
      include: [
        {
          associationUser: {
            user: true,
          },
        },
      ],
    },
    syncWithLocation: true,
  });

  const { edit, show, create, showUrl } = useNavigation();
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "id",
    direction: "ascending",
  });

  const apiUrl = useApiUrl();

  const renderCell = useCallback(
    (columnKey: string, item: ChangeRequest) => {
      const column = columns.find((column) => column.key === columnKey);

      if (columnKey === "associationUser") {
        const user = item.associationUser?.user;
        return (
          <TableCell>
            <User
              avatarProps={{
                radius: "lg",
                src: user?.photoId
                  ? `${apiUrl}/files/${user?.photoId}`
                  : undefined,
                className: user?.photoId ? "bg-light" : "bg-primary",
                color: "primary",
              }}
              description={item.associationUser.user?.email}
              name={`${user.firstName} ${user.lastName}`}
            ></User>
          </TableCell>
        );
      }

      if (columnKey === "type") {
        return (
          <TableCell>
            {item.type === "user-image" ? "Profile image" : item.type}
          </TableCell>
        );
      }

      if (columnKey === "status") {
        return (
          <TableCell>
            <StatusBadge changeRequest={item} />
          </TableCell>
        );
      }

      return (
        <TableCell>{getDotNotationPropertyValue(item, columnKey)}</TableCell>
      );
    },
    [tableQueryResult?.data?.data, user]
  );

  return (
    <>
      <Table
        selectionMode={"none"}
        color={"primary"}
        isStriped={false}
        aria-label="Change requests table"
        sortDescriptor={sortDescriptor}
        onSortChange={(e) => {
          const sorter: CrudSort = {
            order: e.direction === "ascending" ? "asc" : "desc",
            field: e.column as string,
          };

          const field = columns.find((column) => column.key === e.column)?.key;

          if (field) {
            sorter.field = field;
          }

          setSorters([sorter]);
          setSortDescriptor(e);
        }}
        onRowAction={(id) => {
          // const resource = `/association/${activeAssociation?.id}/user`;
          show(ResourceIdentifier.ChangeRequest, `${id}`);
        }}
        topContent={
          <TopContent
            setCurrent={setCurrent}
            setFilters={setFilters}
            filters={filters}
          />
        }
        bottomContent={
          <TableBottomContent
            current={current}
            pageCount={pageCount}
            setCurrent={setCurrent}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        }
      >
        <TableHeader columns={columns}>
          {(column) => {
            if (column.key === "actions") {
              return (
                <TableColumn
                  accessKey={column.key}
                  allowsSorting={column.sortable}
                  key={column.key}
                  className="text-center px-4 w-20"
                >
                  {column.header}
                </TableColumn>
              );
            }
            return (
              <TableColumn allowsSorting={column.sortable}>
                {column.header}
              </TableColumn>
            );
          }}
        </TableHeader>
        {tableQueryResult?.data?.data.length ? (
          <TableBody items={tableQueryResult?.data?.data}>
            {(item) => {
              return (
                <TableRow className={"cursor-pointer"} key={item.id}>
                  {(columnKey) => {
                    return renderCell(columnKey as string, item);
                  }}
                </TableRow>
              );
            }}
          </TableBody>
        ) : (
          <TableBody emptyContent={"No rows to display."}>{[]}</TableBody>
        )}
      </Table>
    </>
  );
};

export type TableTopContentProps = {
  setCurrent: useTableReturnType["setCurrent"];
  setFilters: useTableReturnType["setFilters"];
  filters: useTableReturnType["filters"];
};
export const TopContent: FC<TableTopContentProps> = ({
  setCurrent,
  setFilters,
  filters,
}) => {
  const { toggleDrawer } = useDrawer();
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between gap-3">
        <TableTitle title={"Change requests"}></TableTitle>
      </div>
      <div className="flex justify-end items-end">
        {/*<MultipleSelectionDropdown selected={selected} />*/}
        <TableSearchInput
          setFilters={setFilters}
          filters={filters}
          setCurrent={setCurrent}
        />
      </div>
    </div>
  );
};
