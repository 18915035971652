import { Button, Card, CardBody, Input } from "@nextui-org/react";
import { useNotification, useForgotPassword } from "@refinedev/core";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const ForgotPassword = () => {
  const { mutateAsync: forgotPassword, isLoading } = useForgotPassword<{
    email: string;
  }>();

  const schema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email").required(),
  });

  const form = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const { open } = useNotification();

  return (
    <div className={"bg-primary"}>
      <div className={"flex justify-center align-middle items-center h-screen"}>
        <Card className={"md:p-8 py-8 w-full max-w-md mx-2"}>
          <CardBody>
            <FormProvider {...form}>
              <form
                onSubmit={handleSubmit(
                  async (data) => {
                    await forgotPassword(data);
                    open?.({
                      message: "Check your email, for password reset email.",
                      type: "success",
                    });
                  },
                  (error) => {
                    open?.({
                      message: error?.root?.message || "An error occured",
                      type: "error",
                    });
                  }
                )}
              >
                <div className={"flex flex-col gap-4 mb-6"}>
                  <Input
                    label="Email"
                    variant={"bordered"}
                    className={"max-w-sm"}
                    isInvalid={!!errors.email?.message}
                    errorMessage={errors.email?.message}
                    placeholder={"Enter your email"}
                    {...register("email")}
                  />
                </div>
                <Button
                  size={"lg"}
                  color={"secondary"}
                  type={"submit"}
                  fullWidth={true}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                >
                  Send password reset email
                </Button>
              </form>
            </FormProvider>
          </CardBody>
        </Card>
      </div>
    </div>
    // )}
  );
};
