import { Select, SelectProps, Switch, SwitchProps } from "@nextui-org/react";
import React, { ReactNode } from "react";
import { Control, Controller } from "react-hook-form";

export type FormSwitchProps = {
  name: string;
  control: Control<any, any>;
  label: ReactNode;
} & SwitchProps;

export const FormSwitch: React.FC<FormSwitchProps> = ({
  name,
  children,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={props.control}
      render={({ field, fieldState, formState }) => {
        return (
          <Switch isSelected={field.value} onValueChange={field.onChange}>
            {props.label}
          </Switch>
        );
      }}
    ></Controller>
  );
};
