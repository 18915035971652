import {
  Button,
  Card,
  CardBody,
  Image,
  Input,
  Spacer,
} from "@nextui-org/react";
import { useLogin, useNotification } from "@refinedev/core";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { IconEye, IconEyeClosed } from "@tabler/icons-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const Login = () => {
  const { mutate: login, isLoading } = useLogin<{
    email: string;
    password: string;
  }>();

  const schema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email").required(),
    password: Yup.string().required(),
  });

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const { open } = useNotification();

  return (
    <div className={"w-100 h-100 bg-primary"}>
      <div className={"flex justify-center align-middle items-center h-screen"}>
        <Card className={"md:p-16 py-16"}>
          <CardBody>
            <div className={"flex flex-row justify-center"}>
              <Image
                src={`${
                  import.meta.env.VITE_PUBLIC_BACKEND_API_BASE_URL
                }/cpg-logo.png`}
                className={"w-36 h-36"}
              />
            </div>
            <h1 className={"text-2xl mb-5"}>Login to PGA Admin panel</h1>
            <FormProvider {...form}>
              <form
                onSubmit={handleSubmit(
                  (data) => {
                    login(data);
                  },
                  (error) => {
                    open?.({
                      message: error?.root?.message || "An error occured",
                      type: "error",
                    });
                  }
                )}
              >
                <div className={"flex flex-col mb-6"}>
                  <Input
                    type={"email"}
                    label="Email"
                    variant={"bordered"}
                    isInvalid={!!errors.email?.message}
                    errorMessage={errors.email?.message}
                    placeholder={"Enter your email"}
                    className={"mb-4"}
                    {...register("email")}
                  />
                  <PasswordInput />
                  <Link to="/forgot-password" className={"text-xs mt-1"}>
                    Forgot Password?
                  </Link>
                </div>
                <Button
                  size={"lg"}
                  color={"secondary"}
                  type={"submit"}
                  fullWidth={true}
                  isDisabled={isLoading}
                  isLoading={isLoading}
                >
                  Login
                </Button>
              </form>
            </FormProvider>
          </CardBody>
        </Card>
      </div>
    </div>
    // )}
  );
};

const PasswordInput = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  const {
    formState: { errors },
    control,
    register,
  } = useFormContext();
  return (
    <Input
      label="Password"
      variant="bordered"
      isInvalid={!!errors.password?.message}
      errorMessage={errors.password?.message as string}
      // placeholder="Enter your password"
      endContent={
        <button
          className="focus:outline-none"
          type="button"
          onClick={toggleVisibility}
        >
          {isVisible ? (
            <IconEyeClosed className="text-2xl text-default-400 pointer-events-none" />
          ) : (
            <IconEye className="text-2xl text-default-400 pointer-events-none" />
          )}
        </button>
      }
      type={isVisible ? "text" : "password"}
      className="max-w-sm"
      {...register("password")}
    ></Input>
  );
};
