import { DataProvider, MetaQuery } from "@refinedev/core";
import { interpolate } from "framer-motion";
import {
  REFRESH_TOKEN_KEY,
  TOKEN_EXPIRES_KEY,
  TOKEN_KEY,
} from "../lib/constants";
import { axiosInstance, generateSort, generateFilter } from "./utils";
import { AxiosInstance } from "axios";
import { stringify } from "query-string";

type MethodTypes = "get" | "delete" | "head" | "options";
type MethodTypesWithBody = "post" | "put" | "patch";

import createAuthRefreshInterceptor from "axios-auth-refresh";

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any) =>
  axiosInstance
    .post(
      `${import.meta.env.VITE_PUBLIC_BACKEND_API_URL}/auth/refresh`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(REFRESH_TOKEN_KEY),
        },
      }
    )
    .then((tokenRefreshResponse) => {
      localStorage.setItem(TOKEN_KEY, tokenRefreshResponse.data.token);
      localStorage.setItem(
        TOKEN_EXPIRES_KEY,
        tokenRefreshResponse.data.tokenExpires
      );
      localStorage.setItem(
        REFRESH_TOKEN_KEY,
        tokenRefreshResponse.data.refreshToken
      );

      failedRequest.response.config.headers["Authorization"] =
        "Bearer" + tokenRefreshResponse.data.token;

      return Promise.resolve();
    });

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(TOKEN_KEY);
    const tokenExpiration = localStorage.getItem(TOKEN_EXPIRES_KEY);
    const isExpired = !tokenExpiration || Number(tokenExpiration) < Date.now();
    if (token && config?.headers && !isExpired) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (!import.meta.env.VITE_PUBLIC_SELF_DOMAIN) {
      console.warn("VITE_PUBLIC_SELF_DOMAIN" + " not found in environment");
    }

    config.headers["x-application"] = "CPG-ADMIN";
    config.headers["x-application-domain"] =
      import.meta.env.VITE_PUBLIC_SELF_DOMAIN;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): Omit<
  Required<DataProvider>,
  "createMany" | "updateMany" | "deleteMany"
> => ({
  getList: async ({ resource, pagination, filters, sorters, meta }) => {
    const interpolatedResource = interpolateResource({ resource, meta });
    const url = `${apiUrl}/${interpolatedResource}`;

    const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

    const { headers: headersFromMeta, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const queryFilters = generateFilter(filters);

    const includes = meta?.include;

    const query: {
      // _start?: number;
      // _end?: number;
      sortBy?: string;
      order?: string;
      page?: number;
      limit?: number;
    } = {};

    if (mode === "server") {
      query.page = current;
      query.limit = pageSize;
    }

    const generatedSort = generateSort(sorters);
    if (generatedSort) {
      const { sortBy } = generatedSort;
      query.sortBy = sortBy.join(",");
      // query.order = _order.join(",");
    }

    let requestUrl = `${url}?`;

    const queryString = stringify(query, { encode: false });
    const filterString = stringify(queryFilters, { encode: false });

    if (queryString !== "") {
      requestUrl = `${requestUrl}${queryString}`;
    }
    if (filterString !== "") {
      requestUrl = `${requestUrl}&${filterString}`;
    }

    const { data, headers } = await httpClient[requestMethod](requestUrl, {
      headers: headersFromMeta,
    });

    return {
      data: data?.data ?? data,
      total: data?.meta?.totalItems || data.length,
    };
  },

  getMany: async ({ resource, ids, meta }) => {
    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const interpolatedResource = interpolateResource({ resource, meta });
    const { data } = await httpClient[requestMethod](
      `${apiUrl}/${interpolatedResource}?${stringify({ id: ids })}`,
      { headers }
    );

    return {
      data,
    };
  },

  create: async ({ resource, variables, meta }) => {
    const interpolatedResource = interpolateResource({ resource, meta });

    const url = `${apiUrl}/${interpolatedResource}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "post";

    const { data } = await httpClient[requestMethod](url, variables, {
      headers,
    });

    return {
      data,
    };
  },

  update: async ({ resource, id, variables, meta }) => {
    const interpolatedResource = interpolateResource({ resource, meta });

    const url = `${apiUrl}/${interpolatedResource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "patch";

    const { data } = await httpClient[requestMethod](url, variables, {
      headers,
    });

    return {
      data,
    };
  },

  getOne: async ({ resource, id, meta }) => {
    const interpolatedResource = interpolateResource({ resource, meta });
    const url = `${apiUrl}/${interpolatedResource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";

    const { data } = await httpClient[requestMethod](url, { headers });

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables, meta }) => {
    const interpolatedResource = interpolateResource({ resource, meta });

    const url = `${apiUrl}/${interpolatedResource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "delete";

    const { data } = await httpClient[requestMethod](url, {
      data: variables,
      headers,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { sortBy: sortQuery } = generatedSort;

        const sortQueryString = stringify(sortQuery);

        if (sortQueryString !== "") {
          requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
        }
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      const filterQueryString = stringify(filterQuery);

      if (filterQueryString !== "") {
        requestUrl = `${requestUrl}&${stringify(filterQuery, {
          encode: false,
        })}`;
      }
    }

    if (query && query !== "") {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload, {
          headers,
        });
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
          headers: headers,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl, {
          headers,
        });
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

const interpolateResource = ({
  resource,
  meta,
}: {
  resource: string;
  meta: MetaQuery | undefined;
}) => {
  const interpolations = resource.match(/:.*?\//g)?.map((el) => {
    return {
      matched: el.substring(1, el.length - 1),
      interpolatedValue: meta?.[el.substring(1, el.length - 1)],
    };
  });

  let interpolated = resource;
  if (interpolations) {
    interpolations.forEach((el) => {
      interpolated = interpolated.replace(
        `:${el.matched}`,
        el.interpolatedValue
      );
    });
  }
  return interpolated;
};
