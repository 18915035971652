import { Card, CardBody } from "@nextui-org/react";
import React from "react";
import { AccountDropdown } from "../ui/account-dropdown";

export const Menu = () => {
  return (
    <nav className="my-4 flex justify-end md:flex hidden">
      <Card>
        <CardBody>
          <AccountDropdown></AccountDropdown>
        </CardBody>
      </Card>
    </nav>
  );
};
