import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./crop-image";
import Resizer from "react-image-file-resizer";

interface CropperModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  image: string;
  onSetCroppedImage: (image: string) => void;
}

export const CropperModal = ({
  isOpen,
  onOpenChange,
  image,
  onSetCroppedImage,
}: CropperModalProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const setCroppedImage = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    const resizedImage = await resizeFile(croppedImage as File);
    console.log("donee", { croppedImage });
    onSetCroppedImage(resizedImage as string);
  };

  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "PNG",
        90,
        0,
        (uri) => {
          console.log("Resized image", { uri });
          resolve(uri);
        },
        "base64"
      );
    });

  return (
    <Modal
      isOpen={isOpen}
      backdrop="blur"
      onOpenChange={onOpenChange}
      disableAnimation={true}
      placement={"center"}
      size={"full"}
      className={"xl:max-h-[800px] xl:max-w-[800px] rounded"}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="z-10 flex flex-col gap-1 bg-white">
              <h3>Profile image</h3>
              <p className="text-gray-500 text-sm">
                Crop and adjust your profile image
              </p>
            </ModalHeader>
            <ModalBody className={"p-6 h-full overflow-hidden"}>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                minZoom={1}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={true}
                classes={{
                  containerClassName: "bg-primary",
                  // mediaClassName: "bg-secondary/50",
                }}
              />
            </ModalBody>
            <ModalFooter className={"bg-light z-10"}>
              <Button color="danger" variant="faded" onPress={onClose}>
                Close
              </Button>
              <Button
                variant={"solid"}
                color="success"
                onPress={async () => {
                  await setCroppedImage();
                  onClose();
                }}
              >
                Crop
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
