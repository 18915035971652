import {
  Card,
  Image,
  CardFooter,
  Button,
  CardBody,
  Divider,
  Chip,
} from "@nextui-org/react";
import { useCustom } from "@refinedev/core";
import { IconCheck, IconCross, IconX } from "@tabler/icons-react";
import React from "react";
import { useParams } from "react-router-dom";
import {
  AssociationUserShowProvider,
  useAssociationUserShow,
} from "../../context/user-show.context";
import { useFileEntityUrl } from "../../hooks";
import { getCountryFlagUrl } from "../../lib/flag-map";
import { ResourceIdentifier } from "../../resources";
import { AssociationMembership, AssociationUser } from "../../sdk";
import { Card1 } from "../members/user-view/card-1";
import { Card2 } from "../members/user-view/card-2";
import { Card3 } from "../members/user-view/card-3";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { UserCards } from "./user-cards"; // requires a loader

function AppSkeleton() {
  return null;
}

export const PublicUserView = () => {
  const { userId, associationUserId } = useParams();

  const { data: associationUserData, isFetching } = useCustom<AssociationUser>({
    url: `${
      import.meta.env.VITE_PUBLIC_BACKEND_API_URL
    }/public-profile/${userId}/${associationUserId}`,
    method: "get",
  });

  return (
    <div
      className={
        "bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))]  from-primary-shade via-primary to-primary-shade h-screen mx-auto"
      }
    >
      {associationUserData?.data && (
        <AssociationUserShowProvider
          associationUser={associationUserData?.data}
        >
          {/*<UserCards />*/}
          <UserView />
        </AssociationUserShowProvider>
      )}
      {isFetching && <AppSkeleton></AppSkeleton>}
    </div>
  );
};

export const UserView = () => {
  const { associationUser } = useAssociationUserShow();
  console.log({ associationUser });
  return (
    <div className={"p-8 gap-y-2 flex flex-col"}>
      <div className="flex justify-center my-2">
        <Image
          alt="Woman listing to music"
          className="object-cover rounded-full border-4 border-secondary"
          height={250}
          src={useFileEntityUrl(associationUser?.user?.photoId)}
          width={250}
        />
      </div>

      <Card className={"bg-white/90"}>
        <CardBody>
          <p className={"text-slate-500"}>User name</p>
          <b>
            {associationUser?.user?.firstName} {associationUser?.user?.lastName}
          </b>
        </CardBody>
      </Card>

      <Card className={"bg-white/90"}>
        <CardBody>
          <p className={"text-slate-500"}>Role</p>
          <span>
            <b>{associationUser?.associationRole.name}</b>
          </span>
        </CardBody>
      </Card>

      <Card className={"bg-white/90"}>
        <CardBody>
          <p className={"text-slate-500"}>Association</p>
          <div className={"flex flex-row"}>
            <span className={"flex"}>
              <b>{associationUser?.association.name}</b>
            </span>
            <CountryFlag />
          </div>
        </CardBody>
      </Card>

      <Card
        className={`bg-white/100 border-4 ${
          isValidMembership(associationUser?.membership)
            ? "border-success"
            : "border-danger"
        }`}
      >
        <CardBody>
          <p className={"text-slate-500"}>Membership</p>
          <span className={"flex"}>
            <b className={"mr-1"}>
              {isValidMembership(associationUser?.membership)
                ? "Valid membership"
                : "Invalid membership"}
            </b>
            {isValidMembership(associationUser?.membership) ? (
              <IconCheck className={"text-success"} />
            ) : (
              <IconX className={"text-danger"} />
            )}
          </span>
        </CardBody>
      </Card>
    </div>
  );
};

const isValidMembership = (memberships?: AssociationMembership[]) => {
  if (!memberships) return false;
  const currentDate = Date.now();
  return memberships.some(
    (membership) =>
      new Date(membership["startDate"]).getTime() < currentDate &&
      new Date(membership["endDate"]).getTime() > currentDate
  );
};

const CountryFlag = () => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div
      className={
        "w-10 h-10 text-large border-secondary rounded-full flex flex-col justify-center absolute top-5 right-5"
      }
    >
      <Image
        src={getCountryFlagUrl(associationUser?.association?.country)}
        height={180}
        width={180}
      />
    </div>
  );
};
