import { InputProps } from "@nextui-org/input/dist/input";
import { Input } from "@nextui-org/react";
import React from "react";
import { Control, Controller } from "react-hook-form";

export type FormDatepickerProps = {
  name: string;
  control: Control<any, any>;
} & InputProps;
export const FormDatePicker: React.FC<FormDatepickerProps> = ({
  name,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={props.control}
      render={({ field, fieldState, formState }) => {
        return (
          <Input
            placeholder={"Date"}
            type={"date"}
            {...props}
            isInvalid={!!formState.errors?.[name]?.message}
            errorMessage={formState.errors?.[name]?.message?.toString()}
            value={field.value}
            onChange={field.onChange}
          />
        );
      }}
    ></Controller>
  );
};
