import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@nextui-org/react";
import { useCreate, useInvalidate, useUpdate } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import React from "react";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { FormInput } from "../../../components/ui/form/form-input";
import { useActiveAssociation } from "../../../context/active-association.context";
import { useDrawer } from "../../../context/drawer.context";
import { ResourceIdentifier } from "../../../resources";
import { AssociationGolfClass } from "../../../sdk";

export const UpsertAssociationGolfClassForm: React.FC<{
  associationGolfClass?: AssociationGolfClass;
}> = ({ associationGolfClass }) => {
  const { closeDrawer } = useDrawer();
  const association = useActiveAssociation();

  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const invalidate = useInvalidate();
  const form = useForm({
    defaultValues: {
      name: "",
    },
    values: {
      name: associationGolfClass?.name || "",
    },
    resolver: yupResolver(schema),
    refineCoreProps: {
      resource: ResourceIdentifier.AssociationGolfClass,
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const [loading, setLoading] = React.useState(false);

  const { mutate: updateAssociationGolfClass } = useUpdate();
  const { mutate: createAssociationGolfClass } = useCreate();

  const onSuccess = async () => {
    await invalidate({
      resource: ResourceIdentifier.Association,
      id: association?.id,
      invalidates: ["detail"],
    });
    closeDrawer();
  };

  const onSettled = () => {
    setLoading(false);
  };
  const upsertAssociation = async (data: yup.InferType<typeof schema>) => {
    setLoading(true);
    const resource = ResourceIdentifier.AssociationGolfClass;
    const upsertArgs = {
      resource,
      values: {
        ...data,
        association: {
          id: association,
        }?.id,
      },
      successNotification: {
        message: "Golf class created successfully",
        type: "success" as const,
      },
      errorNotification: (error: any) => {
        console.log("ERROR ", error);
        return {
          message: "Error updating Golf class",
          description: error?.message,
          type: "error" as const,
          position: "top-center",
        };
      },
    };

    if (!associationGolfClass?.id) {
      createAssociationGolfClass(
        {
          ...upsertArgs,
        },
        {
          onSuccess: onSuccess,
          onSettled: onSettled,
        }
      );
    } else {
      await updateAssociationGolfClass(
        {
          ...upsertArgs,
          id: associationGolfClass?.id!,
          successNotification: {
            message: "Golf class updated successfully",
            type: "success" as const,
          },
        },
        {
          onSuccess: onSuccess,
          onSettled: onSettled,
        }
      );
    }
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(upsertAssociation)}>
        <div className={"grid gap-4 mb-6"}>
          <FormInput
            name={"name"}
            label={"Golf class name"}
            variant={"bordered"}
            control={control}
          />
        </div>
        <Button
          fullWidth
          size={"lg"}
          color={"secondary"}
          type={"submit"}
          isLoading={loading}
          isDisabled={loading}
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
};
