import { Image } from "@nextui-org/react";
import React from "react";

export const CpgSponsors = () => {
  return (
    <div
      className={
        "bg-content3 h-full w-full border-t-secondary border-t-2 flex flex-row justify-around gap-x-10 p-5 items-center"
      }
    >
      <Image
        className={"h-10 bg-none max-w-20"}
        src={"/images/golfbidder.svg"}
        height={30}
      />

      <Image
        className={"h-10 max-w-20"}
        src={"https://eu.ping.com/web/assets/media/images/logo.svg"}
        height={30}
      />

      <Image
        className={"h-10 max-w-20"}
        height={30}
        src={"https://www.trackman.com/Trackman_logo--dark.svg"}
      />
    </div>
  );
};
