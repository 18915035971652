import { Spinner, useDisclosure } from "@nextui-org/react";
import { useCustom } from "@refinedev/core";
import React, { FC, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useActiveAssociation } from "../../../context/active-association.context";
import { AddUserToAssociationModal } from "../../../pages/members/upsert/email-already-exists-modal";
import { AssociationUser, User } from "../../../sdk";
import { FormInput } from "../../ui/form/form-input";

export type EmailInputProps = {
  form: UseFormReturn<any>;
  associationUser?: AssociationUser;
  setExistingUser?: (user: User) => void;
};
export const EmailInput: FC<EmailInputProps> = ({
  form,
  associationUser,
  setExistingUser,
}) => {
  const activeAssociation = useActiveAssociation();

  let emailWatch = form.watch("email");

  const { data: email, isFetching: isEmailLoading } = useCustom({
    url: `${import.meta.env.VITE_PUBLIC_BACKEND_API_URL}/users`,
    config: {
      filters: [
        {
          field: "email",
          operator: "eq",
          value: encodeURIComponent(emailWatch),
        },
      ],
    },
    method: "get",
  });

  const { data: user, isFetching: isAssociationUserLoading } = useCustom<User>({
    url: `${import.meta.env.VITE_PUBLIC_BACKEND_API_URL}/users/${
      email?.data?.data?.[0]?.id
    }`,
    method: "get",
    queryOptions: {
      enabled: !!email?.data?.data?.[0]?.id,
      queryKey: ["associationUser", email?.data?.data?.[0]?.id],
    },
  });

  useEffect(() => {
    if (emailWatch === "") {
      form.clearErrors("email");
      return;
    }

    if (isAssociationUserLoading || isEmailLoading) {
      return;
    }

    if (email?.data?.data?.length > 0 && !associationUser) {
      form.setError("email", {
        type: "manual",
        message: "User with this email already exists",
      });

      if (
        !user?.data?.associationUsers?.some(
          (el) => el.associationId === activeAssociation?.id
        )
      ) {
        onOpenInviteToAssociation();
      }
    } else {
      form.clearErrors("email");
    }
  }, [
    emailWatch,
    user?.data,
    email?.data,
    isEmailLoading,
    isAssociationUserLoading,
  ]);

  const {
    isOpen: isInviteToAssociationOpen,
    onOpen: onOpenInviteToAssociation,
    onOpenChange,
  } = useDisclosure();

  if (!activeAssociation) {
    return null;
  }

  return (
    <>
      <AddUserToAssociationModal
        onOpenChange={onOpenChange}
        isOpen={isInviteToAssociationOpen}
        user={user?.data!}
        setExistingUser={setExistingUser}
        associationUser={user?.data?.associationUsers?.find(
          (el) => el.associationId === activeAssociation?.id
        )}
      />
      <FormInput
        isDisabled={!!associationUser}
        name={"email"}
        label={"Email"}
        variant={"bordered"}
        control={form.control}
        endContent={
          <div className={"flex flex-col h-full justify-center"}>
            {(isEmailLoading || isAssociationUserLoading) && (
              <Spinner size={"sm"} />
            )}
          </div>
        }
      />
    </>
  );
};
