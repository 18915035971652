import { Card, Skeleton } from "@nextui-org/react";
import {
  useGetIdentity,
  useIsAuthenticated,
  useIsExistAuthentication,
  useOne,
} from "@refinedev/core";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ApiSdk } from "../lib/api-sdk";
import { ACTIVE_ASSOCIATION_KEY } from "../lib/constants";
import { ResourceIdentifier } from "../resources";
import { Association, User } from "../sdk";

export const ActiveAssociationContext = createContext<Association | null>(null);

interface Props {
  children: React.ReactNode;
}

function getInitialState() {
  const association = localStorage.getItem(ACTIVE_ASSOCIATION_KEY);
  return association ? JSON.parse(association) : null;
}

export const ActiveAssociationProvider: FC<Props> = ({ children }) => {
  const params = useParams();
  const { data } = useGetIdentity<User>();
  const [association, setAssociation] = useState(getInitialState());
  const [isSuperAdmin, setSuperAdmin] = useState(false);

  const { data: associationData } = useOne({
    resource: ResourceIdentifier.Association,
    id: data?.associationUsers?.[0]?.associationId || params?.associationId,
  });

  const navigate = useNavigate();
  useEffect(() => {
    const activeAssociation = data?.associationUsers?.[0]?.association;

    if (
      data?.roles?.some((role) => role.name === "superadmin") &&
      !params?.associationId
    ) {
      navigate("/association/");
      return;
    }

    if (!activeAssociation && !associationData?.data) {
      return;
    }

    if (data?.roles?.some((role) => role.name !== "superadmin")) {
      localStorage.setItem(
        ACTIVE_ASSOCIATION_KEY,
        JSON.stringify(associationData?.data || activeAssociation)
      );
    }

    setAssociation(associationData?.data);
  }, [association, associationData]);

  useEffect(() => {
    if (data?.roles?.some((role) => role.name == "superadmin")) {
      setSuperAdmin(true);
    }
  }, [data?.roles]);

  return (
    <ActiveAssociationContext.Provider value={association}>
      {isSuperAdmin || association ? children : <AppSkeleton />}
    </ActiveAssociationContext.Provider>
  );
};

export const useActiveAssociation = () => {
  return useContext(ActiveAssociationContext);
};

const AppSkeleton = () => {
  return (
    <div className={"flex justify-center align-middle items-center h-screen"}>
      <Card
        className="w-[200px] md:w-full max-w-lg md:h-screen space-y-5 p-4"
        radius="lg"
      >
        <SkeletonContents />
        <div className={"hidden md:block space-y-5"}>
          <SkeletonContents />
          <SkeletonContents />
          <SkeletonContents />
          <SkeletonContents />
          <SkeletonContents />
        </div>
      </Card>
    </div>
  );
};

const SkeletonContents = () => {
  return (
    <>
      <Skeleton className="rounded-lg">
        <div className="h-24 rounded-lg bg-default-300"></div>
      </Skeleton>
      <div className="space-y-3">
        <Skeleton className="w-3/5 rounded-lg">
          <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
        </Skeleton>
        <Skeleton className="w-4/5 rounded-lg">
          <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
        </Skeleton>
        <Skeleton className="w-2/5 rounded-lg">
          <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
        </Skeleton>
      </div>
    </>
  );
};
